import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  CustomInput,
} from "reactstrap";
import Title from "../../Title";
import Button from "../../Button";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";
import config from "../../../../config";

import "./styles.scss";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      name: "",
      surname: "",
      email: "",
      phone: "",
      address: "",
      district: "",
      pobox: "",
      country: "",
      purchaseDate: "",
      deliveryDate: "",
      reference: "",
      serialNumber: "",
      privacy: false,
      userType: false,
      message: "",
      company: "",
      file: "",
      purchaseCountry: "pt",
      error: {
        name: null,
        surname: null,
        email: null,
        phone: null,
        address: null,
        district: null,
        county: null,
        pobox: null,
        country: null,
        purchaseDate: null,
        deliveryDate: null,
        reference: null,
        serialNumber: null,
        privacy: null,
        userType: null,
        message: null,
        company: null,
        purchaseCountry: true,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.setMessage = this.setMessage.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  // handleChange(event) {
  //   this.setState({ status: this.validadeState(event) ? 'ok' : '' });
  // }

  handleChange(event, name, value) {
    const emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const thisElement = event ? event.target.name : name;
    const thisValue = event ? event.target.value : value;

    switch (thisElement) {
      case "email":
        this.setState({
          error: {
            ...this.state.error,
            [thisElement]: emailRE.test(thisValue),
          },
        });
        break;
      case "privacy":
        this.setState({
          error: {
            ...this.state.error,
            [thisElement]: thisValue,
          },
        });
        break;
      case "message":
        this.setState({
          error: {
            ...this.state.error,
            [thisElement]: thisValue.length > 4,
          },
        });
        break;
      case "purchaseCountry":
        this.setState({
          error: {
            ...this.state.error,
            [thisElement]: true,
          },
        });
        break;
      default:
        this.setState({
          error: { ...this.state.error, [thisElement]: thisValue.length > 3 },
        });
    }

    this.setState({
      [thisElement]: thisValue,
    });
  }

  checkIfValid() {
    let status = false;
    if (this.state.userType) {
      if (
        this.state.error.name &&
        this.state.error.surname &&
        this.state.error.email &&
        this.state.error.phone &&
        this.state.error.address &&
        this.state.error.district &&
        this.state.error.county &&
        this.state.error.pobox &&
        this.state.error.country &&
        this.state.error.purchaseCountry &&
        this.state.error.purchaseDate &&
        this.state.error.deliveryDate &&
        this.state.error.reference &&
        this.state.error.serialNumber &&
        this.state.error.privacy &&
        this.state.error.message
      ) {
        status = true;
      }
    } else {
      if (
        this.state.error.purchaseCountry &&
        this.state.error.name &&
        this.state.error.surname &&
        this.state.error.email &&
        this.state.error.phone &&
        this.state.error.privacy &&
        this.state.error.message
      ) {
        status = true;
      }
    }

    // if (
    //   this.state.userType &&
    //   (!this.state.error.company || this.state.error.company === null)
    // ) {
    //   status = false;
    // }
    return status;
  }

  toggleOption(type) {
    this.handleChange(null, type, !this.state[type]);
  }

  handleFileChange({ target: { files } }) {
    const cancel = !files.length;
    if (cancel) return;

    const [{ size, name }] = files;
    const maxSize = 5000000;

    var ext = name.match(/\.([^\.]+)$/)[1];
    switch (ext) {
      case "jpg":
      case "bmp":
      case "png":
      case "tif":
        if (size < maxSize) {
          this.setState({
            file: files,
          });
        }
        break;
      default:
        alert("Not allowed");
    }
  }

  setMessage() {
    try {
      this.setState({ status: "loading" });
      // no content saved lets fecth it fromjson
      var params = new FormData();
      params.append("email", this.state.email);
      if (this.state.userType) {
        // params.append("company", this.state.name);
        params.append("userType", "Assistência técnica");
      } else {
        params.append("userType", "Informações gerais");
      }
      params.append("name", this.state.name);
      params.append("surname", this.state.surname);
      params.append("phone", this.state.phone);
      params.append("address", this.state.address);
      params.append("district", this.state.district);
      params.append("pobox", this.state.pobox);
      params.append("country", this.state.country);
      params.append("purchaseDate", this.state.purchaseDate);
      params.append("deliveryDate", this.state.deliveryDate);
      params.append("reference", this.state.reference);
      params.append("serialNumber", this.state.serialNumber);
      params.append("purchaseCountry", this.state.purchaseCountry);

      params.append("message", this.state.message);
      if (this.state.file[0]) {
        const file = new Blob([this.state.file[0]]); // kind of works and choses stream as content type of file (not request)
        params.append(
          "file",
          file,
          this.state.file[0].name || this.state.file[0].type.replace("/", ".")
        );
      }

      axios
        .post(`${config.apiURL}/wp-json/mail/v1/contact`, params)
        .then((result) => {
          if (result.data.status === "OK") {
            this.setState({ status: "success" });
          } else {
            this.setState({ status: "error" });
          }
        })
        .catch((error) => {
          this.setState({ status: "error" });
        });
    } catch (error) {
      console.log(error);
    }
  }

  renderForm() {
    if (!this.props.content.labels) return null;
    const { title, labels } = this.props.content;

    let status = this.checkIfValid();

    return (
      <div>
        <Form>
          {this.state.status !== "error" && title && <Title>{title}</Title>}
          {this.state.status === "error" && (
            <Title>
              <strong>{this.props.content.messages.error}</strong>
            </Title>
          )}

          <Row>
            <Col xs={12} md={{ size: 10, offset: 1 }}>
              <Label>Assunto</Label>
              <Row className={"options"}>
                <Input
                  onChange={() => this.toggleOption("userType")}
                  id="userType"
                  name="userType"
                  type="select"
                >
                  <option>INFORMAÇÕES GERAIS</option>
                  <option>ASSISTÊNCIA TÉCNICA</option>
                </Input>

                {/* <Col xs={6}>
                  <CustomInput
                    type="radio"
                    id={"user"}
                    className="customRadio"
                    onChange={() => this.toggleOption("userType")}
                    name="userType"
                    label={"Informações gerais"}
                    checked={!this.state.userType}
                  />
                </Col>
                <Col xs={6}>
                  <CustomInput
                    type="radio"
                    id={"company"}
                    className="customRadio"
                    onChange={() => this.toggleOption("userType")}
                    name="userType"
                    label={"Assistência técnica"}
                  />
                </Col> */}
              </Row>
              <Label>País</Label>
              <Row className={"options"}>
                <Input
                  value={this.state.purchaseCountry}
                  onChange={this.handleChange}
                  id="purchaseCountry"
                  name="purchaseCountry"
                  type="select"
                >
                  <option value={"pt"}>Portugal</option>
                  <option value={"es"}>Espanha</option>
                  <option value={"other"}>Outros</option>
                </Input>

                {/* <Col xs={6}>
                  <CustomInput
                    type="radio"
                    id={"user"}
                    className="customRadio"
                    onChange={() => this.toggleOption("userType")}
                    name="userType"
                    label={"Informações gerais"}
                    checked={!this.state.userType}
                  />
                </Col>
                <Col xs={6}>
                  <CustomInput
                    type="radio"
                    id={"company"}
                    className="customRadio"
                    onChange={() => this.toggleOption("userType")}
                    name="userType"
                    label={"Assistência técnica"}
                  />
                </Col> */}
              </Row>
              <br />
              <Row>
                <Col xs={6}>
                  <Label>
                    {labels.name}
                    <Input
                      type={"text"}
                      name={"name"}
                      className={
                        this.state.error.name || this.state.error.name === null
                          ? "valid"
                          : "invalid"
                      }
                      onChange={this.handleChange}
                      value={this.state.name}
                      placeholder={labels.name}
                    />
                  </Label>
                </Col>
                <Col xs={6}>
                  <Label>
                    {"Apelido"}
                    <Input
                      type={"text"}
                      name={"surname"}
                      className={
                        this.state.error.surname ||
                        this.state.error.surname === null
                          ? "valid"
                          : "invalid"
                      }
                      onChange={this.handleChange}
                      value={this.state.surname}
                      placeholder={"Apelido"}
                    />
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Label>
                    Número de telefone
                    <Input
                      type={"phone"}
                      name={"phone"}
                      className={
                        this.state.error.phone ||
                        this.state.error.phone === null
                          ? "valid"
                          : "invalid"
                      }
                      onChange={this.handleChange}
                      value={this.state.phone}
                      placeholder={`Número de telefone`}
                    />
                  </Label>
                </Col>
                <Col xs={6}>
                  <Label>
                    {labels.email}
                    <Input
                      type={"email"}
                      name={"email"}
                      className={
                        this.state.error.email ||
                        this.state.error.email === null
                          ? "valid"
                          : "invalid"
                      }
                      onChange={this.handleChange}
                      value={this.state.email}
                      placeholder={`${labels.email}@${labels.email}`}
                    />
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Label>
                    Morada
                    <Input
                      type={"address"}
                      name={"address"}
                      className={
                        this.state.error.address ||
                        this.state.error.address === null
                          ? "valid"
                          : "invalid"
                      }
                      onChange={this.handleChange}
                      value={this.state.address}
                      placeholder={`Morada`}
                    />
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Label>
                    Distrito
                    <Input
                      type={"district"}
                      name={"district"}
                      className={
                        this.state.error.district ||
                        this.state.error.district === null
                          ? "valid"
                          : "invalid"
                      }
                      onChange={this.handleChange}
                      value={this.state.district}
                      placeholder={`Distrito`}
                    />
                  </Label>
                </Col>
                <Col xs={6}>
                  <Label>
                    Concelho
                    <Input
                      type={"county"}
                      name={"county"}
                      className={
                        this.state.error.county ||
                        this.state.error.county === null
                          ? "valid"
                          : "invalid"
                      }
                      onChange={this.handleChange}
                      value={this.state.county}
                      placeholder={`Concelho`}
                    />
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Label>
                    Codigo Postal
                    <Input
                      type={"pobox"}
                      name={"pobox"}
                      className={
                        this.state.error.pobox ||
                        this.state.error.pobox === null
                          ? "valid"
                          : "invalid"
                      }
                      onChange={this.handleChange}
                      value={this.state.pobox}
                      placeholder={`Codigo Postal`}
                    />
                  </Label>
                </Col>
                <Col xs={6}>
                  <Label>
                    País
                    <Input
                      type={"country"}
                      name={"country"}
                      className={
                        this.state.error.country ||
                        this.state.error.country === null
                          ? "valid"
                          : "invalid"
                      }
                      onChange={this.handleChange}
                      value={this.state.country}
                      placeholder={`País`}
                    />
                  </Label>
                </Col>
              </Row>
              {this.state.userType && (
                <>
                  <Row>
                    <Col xs={6}>
                      <Label>
                        Data de Compra
                        <Input
                          type={"purchaseDate"}
                          name={"purchaseDate"}
                          className={
                            this.state.error.purchaseDate ||
                            this.state.error.purchaseDate === null
                              ? "valid"
                              : "invalid"
                          }
                          onChange={this.handleChange}
                          placeholder={"Data de Compra"}
                        />
                      </Label>
                    </Col>
                    <Col xs={6}>
                      <Label>
                        Data de Entrega
                        <Input
                          type={"deliveryDate"}
                          name={"deliveryDate"}
                          className={
                            this.state.error.deliveryDate ||
                            this.state.error.deliveryDate === null
                              ? "valid"
                              : "invalid"
                          }
                          onChange={this.handleChange}
                          placeholder={"Data de Entrega"}
                        />
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>
                        Referência e Descrição
                        <Input
                          type={"reference"}
                          name={"reference"}
                          className={
                            this.state.error.reference ||
                            this.state.error.reference === null
                              ? "valid"
                              : "invalid"
                          }
                          onChange={this.handleChange}
                          placeholder={"Referência e Descrição"}
                        />
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>
                        Número de Serie
                        <Input
                          type={"serialNumber"}
                          name={"serialNumber"}
                          className={
                            this.state.error.serialNumber ||
                            this.state.error.serialNumber === null
                              ? "valid"
                              : "invalid"
                          }
                          onChange={this.handleChange}
                          placeholder={"Número de Serie"}
                        />
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>
                        Upload de Ficheiros
                        <div
                          className={`dropZoneContainer ${
                            !this.state.file ? "" : "valid"
                          }`}
                        >
                          <Input
                            type={"file"}
                            name={"file"}
                            className={"FileUpload"}
                            placeholder={"Fatura"}
                            accept=".jpg,.png,.pdf"
                            onChange={this.handleFileChange}
                          />
                          <div class="dropZoneOverlay">
                            ARRASTA E INSERE OS FICHEIROS AQUI OU PESQUISA
                          </div>
                        </div>
                      </Label>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col xs={12}>
                  <Label>
                    {this.state.userType
                      ? "Descrição da incidência"
                      : labels.message_body}
                    <Input
                      type="textarea"
                      name="message"
                      className={
                        this.state.error.message ||
                        this.state.error.message === null
                          ? "valid"
                          : "invalid"
                      }
                      onChange={this.handleChange}
                      value={this.state.message}
                      placeholder={
                        this.state.userType
                          ? "Descrição da incidência"
                          : labels.message_body
                      }
                    />
                  </Label>
                  <div className={"errorMessage"}>
                    {this.state.message
                      ? `${
                          this.state.message.length < 5
                            ? `faltam ${Math.abs(
                                this.state.message.length - 5
                              )} palavras`
                            : ""
                        }`
                      : "tens que escrever 5 letras ou mais"}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12} className={"options privacy"}>
                  <CustomInput
                    type="checkbox"
                    id={"privacy"}
                    className="customRadio"
                    name="privacy"
                    onChange={() => this.toggleOption("privacy")}
                    checked={this.state.privacy}
                    label={""}
                  />
                  <Label
                    className={
                      this.state.error.privacy ||
                      this.state.error.privacy === null
                        ? "valid"
                        : "invalid"
                    }
                  >
                    <a href={labels.privacy_link} target={"blank"}>
                      {labels.privacy}
                    </a>
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  className={`${!status ? "notActive" : ""} button-wrapper`}
                >
                  <Button onClick={() => status && this.setMessage()}>
                    {labels.button_text}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
  renderThankyou() {
    return (
      <Title>
        <strong>{this.props.content.messages.success}</strong>
      </Title>
    );
  }
  renderLoading() {
    return (
      <Title>
        <strong>{this.props.content.messages.sending}</strong>
      </Title>
    );
  }

  render() {
    let body = {};
    switch (this.state.status) {
      case "success":
        body = this.renderThankyou();
        break;
      case "loading":
        body = this.renderLoading();
        break;
      default:
        body = this.renderForm();
        break;
    }

    return (
      <div
        className={"contact"}
        style={{
          backgroundColor: `${this.props.content.background}`,
        }}
      >
        <Container>
          <ScrollAnimation
            initiallyVisible={this.props.first}
            animateOnce={true}
            // animateOnce={this.props.first}
            animateIn="fadeIn"
          >
            <div className={"contactus-container"}>{body}</div>
          </ScrollAnimation>
        </Container>
      </div>
    );
  }
}
