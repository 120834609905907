import React from 'react';
import LayoutAlternateImageBackground from '../../Blocks/OLD/LayoutAlternateImageBackground';
import './styles.css';
import config from '../../../../config';

function LayoutListContentAlternateBackground(props) {
  const content = props.content;

  if (!content || !content.content) return <div />;
  return content.content.map((cont, i) => {
    if (!cont.acf.excerpt) return false;
    return (
      <LayoutAlternateImageBackground
        key={i}
        odd={i % 2}
        content={{
          title: cont.title.rendered,
          content: cont.acf.excerpt.summary,
          image: cont.acf.excerpt.image,
          link: cont.link.replace(config.websiteURL, ''),
          button_text: content.button_text,
        }}
      />
    );
  });
}

export default LayoutListContentAlternateBackground;
