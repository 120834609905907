import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  Label,
  Spinner,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { debounce } from "lodash";
import axios from "axios";
import config from "../../../../config";
import csv from "csvtojson";

import { connect } from "react-redux";

// import { catalogo-becken.csv } from "../../../../assets/files/catalogo-becken.csv";
import "./styles.scss";

class Manuais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      keyword: "",
      productMainType: null,
      productSubCat1: null,
      productSubCat2: null,
      categories: [],
      startCtegories: [],
      list: [],
      tooltipOpen: false,
    };
    this.searchKeyword = debounce(this.searchKeyword, 600);
    this.getFilenameForDownload = this.getFilenameForDownload.bind(this);
  }

  componentDidMount() {
    this.parseCSVData();
  }

  /**
   * react csv at with | delimiter
   * read csv from file and parse it to json
   * @param {*} key
   */
  async parseCSVData() {
    const filePath = require("../../../assets/catalogo-becken.csv");
    const text = await fetch(filePath).then((row) => row.text());
    const totalObj = await csv({
      delimiter: "|",
    }).fromString(text);

    //lets find the main category prop GROUP_NAME
    const categories = totalObj
      .map((item) => item.GROUP_NAME)
      .filter((item) => item);
    const uniqueCategories = [...new Set(categories)];

    this.setState({ categories: totalObj, startCtegories: uniqueCategories });
  }

  searchKeyword(key) {
    this.setState({ status: "loading" });
    // no content saved lets fecth it fromjson
    var params = new URLSearchParams();
    const keyword = key || this.state.keyword;
    params.append("keyword", keyword.toUpperCase());

    axios
      .post(`${config.apiURL}/wp-json/manuais/v1/get`, params)
      .then((result) => {
        if (result.data.status === "OK") {
          this.setState({ status: "success", list: result.data.msg || [] });
        } else {
          this.setState({ status: "error" });
        }
      })
      .catch((error) => {
        this.setState({ status: "error" });
      });
  }

  getFilenameForDownload(sku) {
    this.setState({ tooltipOpen: sku });
    // no content saved lets fecth it fromjson
    axios
      .get(`${config.apiURL}/wp-json/manuais/v1/download?sku=${sku}`)
      .then((result) => {
        if (
          result.data.status === "OK" &&
          result?.data?.msg !== "" &&
          result?.data?.msg?.length > 0
        ) {
          if (result?.data?.msg?.length > 1) {
            window.open(
              `${config.apiURL}/wp-content/uploads/manuais/${
                result.data.msg[0]
              }`,
              "_blank"
            );
          } else {
            window.open(
              `${config.apiURL}/wp-content/uploads/manuais/${result.data.msg}`,
              "_blank"
            );
          }

          this.setState({ tooltipOpen: false });
        } else {
          this.setState({ tooltipOpen: false });
        }
      });
  }

  cleanFileName(fileName) {
    const reg = new RegExp("/(?<=_)(.*?)(?=.)/");
    const found = fileName.match(reg);
    return found && found.length >= 1 ? found[0] : fileName;
  }

  renderList() {
    const resultsLen = this.state?.list?.length || 0;
    return (
      <div>
        <ul>
          {this.state.list.map((item, i) => {
            if (item?.CLASS_NAME && item?.DEPT_NAME && item?.GROUP_NAME) {
              return (
                <div>
                  <ScrollAnimation
                    key={i}
                    animateOnce={true}
                    animateIn="fadeInUp"
                  >
                    <li>
                      <a
                        id={"Tooltip-" + i}
                        onClick={(e) => {
                          e.preventDefault();
                          this.getFilenameForDownload(item.SKU);
                        }}
                        // href={`${config.apiURL}/wp-json/manuais/v1/download?sku=${item.SKU}`}
                        className={`clearfix`}
                        style={{ cursor: "pointer" }}
                      >
                        {this.state.tooltipOpen === item.SKU ? (
                          <span>
                            <Spinner />
                          </span>
                        ) : (
                          <span className={`pdf`} />
                        )}
                        <span>{item.DESC_UP}</span>
                        {/* <span>{this.cleanFileName(item.toLowerCase())}</span> */}

                        <span className={`download`} />
                      </a>
                      <p style={{ padding: "0 1em 1em 1em" }}>{item.field3}</p>
                    </li>
                  </ScrollAnimation>
                </div>
              );
            }
            return (
              <ScrollAnimation key={i} animateOnce={true} animateIn="fadeInUp">
                <li>
                  <a
                    href={`${config.apiURL}/wp-content/uploads/manuais/${item}`}
                    target="_blank"
                    className={`clearfix`}
                  >
                    <span className={`pdf`} />
                    <span>{item.toLowerCase()}</span>
                    {/* <span>{this.cleanFileName(item.toLowerCase())}</span> */}
                    <span className={`download`} />
                  </a>
                </li>
              </ScrollAnimation>
            );
          })}
        </ul>
        {this.state.status === "success" && (
          <p>
            {resultsLen < 1
              ? "Não foram encontrados manuais para este critério"
              : `Foram encontrados ${resultsLen} manuais`}
          </p>
        )}
      </div>
    );
  }
  renderForm() {
    // if (!this.props.content.placeholder) return null;
    return (
      <div>
        <Form>
          <Label
            for="exampleproductMainTypeSelect"
            style={{ fontWeight: "bold" }}
          >
            Escolha uma opção
          </Label>
          <Input
            id="productMainType"
            type="select"
            name={"productMainType"}
            onChange={(event) => {
              this.setState({
                productMainType: event.target.value,
                productSubCat1: null,
                productSubCat2: null,
                keyword: "",
                list: [],
              });
            }}
            value={this.state.productMainType}
            placeholder={this.props.content.placeholder || "Pesquisar"}
          >
            <option value={""}>Tipo de Electrodoméstico </option>
            {this.state.startCtegories?.map((item, i) => {
              return (
                <option key={i} value={item}>
                  {item}
                </option>
              );
            })}
          </Input>
          {this.state.productMainType && (
            <div style={{ margin: "1em 0 0 0" }}>
              <Input
                id="productSubCat1"
                type="select"
                name={"productSubCat1"}
                onChange={(event) => {
                  this.setState({
                    productSubCat1: event.target.value,
                    productSubCat2: null,
                    keyword: "",
                    list: [],
                  });
                }}
                value={this.state.productSubCat1}
                placeholder={this.props.content.placeholder || "Pesquisar"}
              >
                <option value={""}>Escolha uma opção</option>
                {this.state?.categories
                  ?.filter(
                    (cat) => cat.GROUP_NAME === this.state.productMainType
                  )
                  .filter(
                    (item, i, ar) =>
                      ar.findIndex((t) => t.DEPT_NAME === item.DEPT_NAME) === i
                  )
                  ?.map((item, i) => {
                    return (
                      <option key={i} value={item.DEPT_NAME}>
                        {item.DEPT_NAME}
                      </option>
                    );
                  })}
              </Input>
            </div>
          )}
          {(this.state.productMainType || this.state.productMainType !== "") &&
            this.state.productSubCat1 && (
              <div style={{ margin: "1em 0 0 0" }}>
                <Input
                  id="productSubCat2"
                  type="select"
                  name={"productSubCat2"}
                  onChange={(event) => {
                    this.setState({
                      productSubCat2: event.target.value,
                      keyword: "",
                      list: this.state?.categories.filter(
                        (cat) =>
                          cat.DEPT_NAME === this.state.productSubCat1 &&
                          cat.GROUP_NAME === this.state.productMainType &&
                          cat.CLASS_NAME === event.target.value
                      ),
                    });
                  }}
                  value={this.state.productSubCat2}
                  placeholder={this.props.content.placeholder || "Pesquisar"}
                >
                  <option value={""}>Escolha uma opção</option>
                  {this.state?.categories
                    ?.filter(
                      (cat) =>
                        cat.DEPT_NAME === this.state.productSubCat1 &&
                        cat.GROUP_NAME === this.state.productMainType
                    )
                    .filter(
                      (item, i, ar) =>
                        ar.findIndex(
                          (t) => t.CLASS_NAME === item.CLASS_NAME
                        ) === i
                    )
                    ?.map((item, i) => {
                      return (
                        <option key={i} value={item.CLASS_NAME}>
                          {item.CLASS_NAME}
                        </option>
                      );
                    })}
                </Input>
              </div>
            )}
          {(!this.state.productMainType || this.state.productMainType === "") &&
            (!this.state.productSubCat1 ||
              this.state.productSubCat1 === "") && (
              <div style={{ margin: "1em 0 0 0" }}>
                <InputGroup>
                  <Input
                    type={"text"}
                    name={"keyword"}
                    onChange={(event) => {
                      this.setState({
                        [event.target.name]: event.target.value,
                        productSubCat1: null,
                        productSubCat2: null,
                        productMainType: null,
                        list: [],
                      });
                      this.searchKeyword(event.target.value);
                    }}
                    value={this.state.keyword}
                    placeholder={
                      // this.props.content.placeholder ||
                      "Pesquisa por nome / referência"
                    }
                  />
                  <div
                    className="buttonSearch"
                    onClick={() => this.searchKeyword(this.state.keyword)}
                  ></div>
                </InputGroup>
              </div>
            )}
        </Form>
      </div>
    );
  }

  render() {
    return (
      <div
        className={"manuais"}
        style={{
          backgroundColor: `rgb(250, 250, 250)`,
        }}
      >
        <Container>
          <Row>
            <Col xs={12} md={{ size: 6, offset: 3 }}>
              <div className={"manuais-input"}>{this.renderForm()}</div>

              <div className={"manuais-list"}>{this.renderList()}</div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  categories: state.content.categories,
});

const mapDispatchToProps = (dispatch) => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Manuais);
