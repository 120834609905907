import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import LayoutGridElement from '../../LayoutGridElement';
import ReadMore from '../../ReadMore';
import ScrollAnimation from 'react-animate-on-scroll';
import config from '../../../../config';
import './styles.css';
import { excerptFrom } from '../../../utils/strings/excerpt';

function LayoutListHighlight(props) {
  const content = props.content;

  if (!content || !content.content) return <div />;

  if (content.content.length !== 3) return false;

  const first = content.content[0] && content.content[0].acf;
  const second = content.content[1] && content.content[1].acf;
  const third = content.content[2] && content.content[2].acf;

  // first content
  const firstImage = first.excerpt && first.excerpt.image;
  const firstLink = content.content[0].link;
  const firstContent = first.excerpt;

  // second content
  const secondImage = second.excerpt && second.excerpt.image;
  const secondLink = content.content[1].link;
  const secondContent = second.excerpt;

  // third content
  const thirdImage = third.excerpt && third.excerpt.image;
  const thirdLink = content.content[2].link;
  const thirdContent = third.excerpt;

  return (
    <div id={props.id} className={'list-highlight'}>
      <Container>
        <Row>
          <Col xs="12" sm="12" md={'6'} className={'image'}>
            <ScrollAnimation
              initiallyVisible={props.first}
              animateOnce={props.first}
              animateIn="fadeIn"
              animateOut="fadeOut"
            >
              <div
                className={'image-background'}
                style={{
                  backgroundImage: `url('${firstImage.sizes &&
                    firstImage.sizes.large}')`,
                }}
              />
            </ScrollAnimation>
          </Col>

          <Col xs="12" sm="12" md={'6'} className={'elements'}>
            <Row>
              <Col xs="12" className={'title'}>
                <ScrollAnimation
                  animateIn="fadeIn"
                  delay={50}
                  animateOut="fadeOut"
                  initiallyVisible={props.first}
                  animateOnce={props.first}
                >
                  <h2>{content.title}</h2>
                  <h6>{content.content[0].title.rendered}</h6>
                  <p>{firstContent.summary}</p>
                  {/* <ReadMore link={firstLink.replace(config.websiteURL, '')}>
                    Saber mais
                  </ReadMore> */}
                </ScrollAnimation>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md={6} className={'element'}>
                <ScrollAnimation
                  animateIn="fadeIn"
                  delay={150}
                  animateOut="fadeOut"
                  initiallyVisible={props.first}
                  animateOnce={props.first}
                >
                  <LayoutGridElement
                    title={content.content[1].title.rendered}
                    content={secondContent.summary}
                    image={secondImage}
                    link={secondLink.replace(config.websiteURL, '')}
                  />
                </ScrollAnimation>
              </Col>
              <Col sm="12" md={6} className={'element'}>
                <ScrollAnimation
                  animateIn="fadeIn"
                  delay={200}
                  animateOut="fadeOut"
                  initiallyVisible={props.first}
                  animateOnce={props.first}
                >
                  <LayoutGridElement
                    title={content.content[2].title.rendered}
                    content={thirdContent.summary}
                    image={thirdImage}
                    link={thirdLink.replace(config.websiteURL, '')}
                  />
                </ScrollAnimation>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LayoutListHighlight;
