import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

function convertNumberToInch(number) {
  return (number * 0.39370078740157).toFixed(2);
}

function LayoutGridElementHover(props) {
  const { id, image, link, title } = props;
  return (
    <div className={`grid-element-hover ${props.first ? 'first' : ''}`}>
      <Link to={link}>
        <div
          className={'image'}
          style={{
            backgroundImage: `url('${image.sizes && image.sizes.large}')`,
          }}
        ></div>
        <div className={'content'}>
          <span className={'blue-line'} />
          <h6>{title}</h6>
        </div>
      </Link>
    </div>
  );
}

export default LayoutGridElementHover;
