import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import ReadMore from '../../../ReadMore';
import './styles.scss';
import ReactPlayer from 'react-player';

function TexBlockBackground(props) {
  function renderVisual() {
    if (props.content.video && props.content.video !== '') {
      return renderVideo();
    } else {
      return renderImage();
    }
  }
  function renderVideo() {
    return (
      <Col xs="12" md="6" className={'align-items-center align-self-center'}>
        <ReactPlayer url={props.content.video} width={'100%'} />
      </Col>
    );
  }
  function renderImage() {
    return (
      <Col
        xs="12"
        md="6"
        className={'align-items-center align-self-center clearfix'}
      >
        <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
          {props.content.link ? (
            <a
              className={
                props.content.image || props.content.image.src
                  ? 'image'
                  : 'noimage'
              }
              href={props.content.link}
            >
              {(props.content.image || props.content.image.src) && (
                <img
                  src={props.content.image.url || props.content.image.src}
                  alt={props.content.image.alt}
                />
              )}
            </a>
          ) : (
            <div
              className={
                props.content.image || props.content.image.src
                  ? 'image'
                  : 'noimage'
              }
            >
              {(props.content.image || props.content.image.src) && (
                <img
                  src={props.content.image.url || props.content.image.src}
                  alt={props.content.image.alt}
                />
              )}
            </div>
          )}
        </ScrollAnimation>
      </Col>
    );
  }

  function renderText() {
    return (
      <div className={'content-wrap'}>
        <div
          className={`${
            props.odd ? 'color-line-right' : 'color-line'
          } animated fadeInUp'`}
        />

        <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
          {props.content.sub_title && (
            <h6
              dangerouslySetInnerHTML={{
                __html: props.content.sub_title,
              }}
            />
          )}
          <h5 dangerouslySetInnerHTML={{ __html: props.content.title }} />

          <div dangerouslySetInnerHTML={{ __html: props.content.content }} />
          {props.content.link && (
            <ReadMore link={props.content.link}>
              {props.content.button_text}
            </ReadMore>
          )}
        </ScrollAnimation>
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className={`horizontal-text-block-background ${
        props.odd === 1 ? 'odd' : 'even'
      } ${props.first ? 'first' : ''} ${
        props.content.with_background ? 'color' : 'white'
      }`}
    >
      <Container>
        <Row>
          {!props.odd && renderVisual()}
          {props.first && (
            <div className={'half-background'}>{renderText()}</div>
          )}
          <Col
            xs="12"
            md="6"
            className={'content align-items-center align-self-center'}
          >
            {!props.first && renderText()}
          </Col>
          {props.odd === 1 && renderVisual()}
        </Row>
      </Container>
    </div>
  );
}

export default TexBlockBackground;
