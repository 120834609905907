import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-responsive-modal";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { debounce } from "lodash";
import ReadMore from "../ReadMore";
import { getSearch, toggleSearch } from "../../containers/Search/actions";
import config from "../../../config";
import "./styles.scss";
import { withRouter } from "react-router-dom";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      active: null,
    };
    this.setSearchTerm = this.setSearchTerm.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.getSearch = debounce(this.getSearch, 600);
  }

  setSearchTerm(e) {
    this.setState({ searchTerm: e.target.value });
    this.getSearch(e.target.value, this.props.lang, 1);
  }

  getSearch(searchTerm) {
    this.props.history.push(`/search/?q=${searchTerm}`);
  }
  toggleActive(state) {
    this.setState({ active: state || !this.state.active });
  }
  render() {
    return (
      <div className={`search-menu ${this.state.active ? "active" : ""}`}>
        <Input
          type={"text"}
          name={"search"}
          onChange={this.setSearchTerm}
          focus={1}
          value={this.state.searchTerm}
          placeholder={""}
        />
        <div
          className={"search-zoom"}
          onClick={() => this.props.closeMenu && this.props.closeMenu()}
        ></div>

        {/* {this.props.results && this.props.results.length > 0 && (
            <Container className={'content'}>
              <Row>
                <Col>
                  {this.props.results.map((result, index) => {
                    const summary =
                      result.acf &&
                      result.acf.excerpt &&
                      result.acf.excerpt.summary
                        ? result.acf.excerpt.summary
                        : result.excerpt.rendered;
                    const iamgeURL =
                      result.acf &&
                      result.acf.excerpt &&
                      result.acf.excerpt.image &&
                      result.acf.excerpt.image.sizes.thumbnail;
                    return (
                      <li key={index}>
                        {iamgeURL && (
                          <div>
                            <img src={iamgeURL} />
                          </div>
                        )}
                        <div>
                          <h6>{result.title.rendered}</h6>
                          {summary}
                          <ReadMore
                            hook={() => this.props.toggleSearch()}
                            link={result.link}
                          >
                            View this page
                          </ReadMore>
                        </div>
                      </li>
                    );
                  })}
                  {this.getMoreContent()}
                </Col>
              </Row>
            </Container>
          )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  displaySearch: state.search.displaySearch,
  status: state.search.status,
  results: state.search.data,
  page: state.search.page,
  lang: state.language,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getSearch, toggleSearch }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
