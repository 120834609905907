import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { scrollToItem } from "../../../utils/scroll";
import "./styles.scss";
import defaultLogo from "../../../assets/logo_white.svg";
import config from "../../../../config";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      mainActive: 0,
      hideSub: false,
    };
    this.setNewActive = this.setNewActive.bind(this);
    this.setNewMainActive = this.setNewMainActive.bind(this);
    this.toggleOverMenu = this.toggleOverMenu.bind(this);
  }

  componentDidUpdate() {
    if (this.state.hideSub) {
      setTimeout(() => this.setState({ hideSub: false }), 500);
    }
  }

  scrollToReservation(link) {
    if (typeof window !== "undefined") {
      const item = document.getElementById(link);
      scrollToItem(item, 150);
    }
  }

  toggleOverMenu(state) {
    this.setState({ opened: state || !this.state.opened });
  }

  setNewMainActive(index) {
    if (this.state.mainActive !== index) this.setState({ mainActive: index });
  }
  setNewActive(index) {
    if (this.state.active !== index) this.setState({ active: index });
  }

  processCatLink(link, i) {
    let thisChildren = [];
    if (!link && this.props.categories["products"]) {
      thisChildren = this.props.categories["products"].filter(
        (cat) => cat.parent === 0 || !cat.parent
      );
    } else if (link) {
      thisChildren = this.props.categories["products"].filter(
        (cat) => cat.parent === link.id
      );
    }

    if (!thisChildren) return null;

    return (
      <ul
        className={`submenu_${i}`}
        onMouseLeave={() => {
          if (i === 1) setTimeout(() => this.setNewMainActive(null), 500);
        }}
      >
        <Container>
          {thisChildren.map((item, index) => {
            return (
              <li
                key={index}
                className={`${
                  this.state.active === index && i === 1 ? "activeSub" : ""
                } fadeIn animated ${
                  !item.childs && !item.child_items ? "notaParent" : ""
                }`}
                onMouseOver={() => i === 1 && this.setNewActive(index)}
                style={{
                  animationDelay: `${index / 6 + 0.01}s`,
                  animationDuration: `.6s`,
                }}
              >
                {this.renderLink(item, i)}
                {this.processCatLink(item, i + 1)}
              </li>
            );
          })}
        </Container>
      </ul>
    );
  }

  processChildLink(link, i) {
    // yap its not consistent, lets normalize
    if (!link.childs && !link.child_items) return null;

    const thisChildren = link.childs ? link.childs : link.child_items;
    return (
      <ul
        className={`submenu_${i}`}
        onMouseLeave={() => {
          setTimeout(() => this.setNewMainActive(null), 500);
        }}
      >
        <Container>
          {thisChildren.map((item, index) => {
            return (
              <li
                key={index}
                className={`${
                  this.state.active === index && i === 1 ? "activeSub" : ""
                } fadeIn animated ${
                  !item.childs && !item.child_items ? "notaParent" : ""
                }`}
                onMouseOver={() => i === 1 && this.setNewActive(index)}
                style={
                  {
                    // animationDelay: `${index / 6 + 0.01}s`,
                    // animationDuration: `.5s`,
                  }
                }
              >
                {this.renderLink(item, i)}
                {this.processChildLink(item, i + 1)}
              </li>
            );
          })}
        </Container>
      </ul>
    );
  }

  renderLink(col, level) {
    if (!col && !col.link && !col.url) return null;
    const link = col.link || col.url;
    const regexWebsite = new RegExp(config.websiteURL);
    const realLink = link && link.replace(regexWebsite, "");

    //this sub menus are not acessible
    if (realLink && realLink[0] === "/") {
      return (
        <Link
          to={realLink}
          className={this.props.location.match(link) ? "active" : "notactive"}
          dangerouslySetInnerHTML={{ __html: col.title || col.name }}
          onClick={() => this.setState({ hideSub: true })}
        />
      );
    } else if (realLink && realLink !== "#") {
      return (
        <a
          href={realLink}
          className={this.props.location.match(link) ? "active" : "notactive"}
          // onClick={() => scrollToReservation(link)}
          dangerouslySetInnerHTML={{ __html: col.title || col.name }}
        />
      );
    } else {
      return (
        <span
          className={
            link && this.props.location.match(link) ? "active" : "notactive"
          }
          onClick={() => this.setState({ hideSub: true })}
          // onClick={() => scrollToReservation(link)}
          dangerouslySetInnerHTML={{ __html: col.title || col.name }}
        />
      );
    }
  }

  render() {
    let logoImageUrl =
      this.props.options &&
      this.props.options.white_logo &&
      this.props.options.white_logo.url
        ? this.props.options.white_logo.url
        : defaultLogo;
    // if (this.props.fixed && this.props.options && this.props.options.white_logo)
    //   logoImageUrl = this.props.options.white_logo.url;
    return (
      <ul className={"wrap clearfix"}>
        <li
          className={"logo fadeIn animated"}
          style={{
            animationDelay: `0s`,
            animationDuration: `1s`,
          }}
        >
          <Link
            to={""}
            style={
              logoImageUrl && {
                backgroundImage: `url('${logoImageUrl}')`,
              }
            }
          />
        </li>
        <li className={"menu-items"}>
          <ul>
            {this.props.content &&
              this.props.content.map((col, index) => {
                return (
                  col.menu_item_parent === "0" && (
                    <li
                      key={index}
                      className={`fadeIn animated ${
                        this.state.mainActive === index ? "active" : "notactive"
                      }`}
                      onMouseOver={() => this.setNewMainActive(index)}
                      style={{
                        animationDelay: `${index - 0.5 + 0.02}s`,
                        animationDuration: `2s`,
                      }}
                    >
                      {this.renderLink(col)}

                      {col.title === "Produtos"
                        ? !this.state.hideSub && this.processCatLink(null, 1)
                        : !this.state.hideSub && this.processChildLink(col, 1)}
                    </li>
                  )
                );
              })}

            {/* <li className={'shop'}>
        <Link to={''} />
      </li> */}
          </ul>
        </li>
      </ul>
    );
  }
}

export default Menu;
