import React from 'react';
import Button from '../Button';
import './styles.scss';
import { Container, Row, Col } from 'reactstrap';

function NoContent(props) {
  return (
    <div className={'section-nocontent'}>
      <Container>
        <Row>
          <Col xs={'12'} md={{ size: 6, offset: 3 }}>
            <div
              className={'content'}
              dangerouslySetInnerHTML={{
                __html:
                  props.content &&
                  props.content.content &&
                  props.content.content['404'],
              }}
            />
            <Button to={'/'}>Homepage</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NoContent;
