import * as constants from "./constants";
import config from "../../../config";

let initialState = {
  modal: {
    id: "",
    open: false,
    source: "",
  },
  topmenu: [],
  submenu: [],
  shopmenu: [],
  footermenu: [],
};

const regexWebsite = new RegExp(config.websiteURL);
const isLocalStorage = typeof localStorage !== "undefined";
let newState = {};
//lets load user data for reload
if (isLocalStorage && localStorage.menus) {
  initialState = JSON.parse(localStorage.menus);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.TOGGLE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          open: !state.modal.open,
          id:
            action.payload.id && action.payload.id !== ""
              ? action.payload.id
              : state.modal.id,
          source:
            action.payload.url &&
            typeof action.payload.url == "string" &&
            action.payload.url !== ""
              ? action.payload.url
              : state.modal.source,
        },
      };
    case constants.SET_CURRENT_MENUS:
      const regexWebsite = new RegExp(config.websiteURL);
      newState = {
        ...state,
        topmenu:
          action.menus.topmenu &&
          action.menus.topmenu.items &&
          Object.keys(action.menus.topmenu.items).map((menu) => {
            const thisMenu = action.menus.topmenu.items[menu];
            return {
              link: thisMenu.url.replace(regexWebsite, ""),
              title: thisMenu.title,
              childs:
                thisMenu.child_items &&
                thisMenu.child_items.map((ele) => {
                  return {
                    link: ele.url.replace(regexWebsite, ""),
                    title: ele.title,
                    childs: ele.child_items,
                    menu_item_parent: ele.menu_item_parent,
                    id: ele.ID,
                  };
                }),
              menu_item_parent: thisMenu.menu_item_parent,
              id: thisMenu.ID,
            };
          }),
        footermenu:
          action.menus.footermenu &&
          action.menus.footermenu.items &&
          Object.keys(action.menus.footermenu.items).map((menu) => {
            const thisMenu = action.menus.footermenu.items[menu];
            return {
              link: thisMenu.url.replace(regexWebsite, ""),
              title: thisMenu.title,
              childs:
                thisMenu.child_items &&
                thisMenu.child_items.map((ele) => {
                  return {
                    link: ele.url.replace(regexWebsite, ""),
                    title: ele.title,
                    childs: ele.child_items,
                    menu_item_parent: ele.menu_item_parent,
                    id: ele.ID,
                  };
                }),
              menu_item_parent: thisMenu.menu_item_parent,
              id: thisMenu.ID,
            };
          }),
        submenu:
          action.menus.submenu &&
          action.menus.submenu.items &&
          Object.keys(action.menus.topmenu.items).map((menu) => {
            const thisMenu = action.menus.topmenu.items[menu];
            return {
              link: thisMenu.url.replace(regexWebsite, ""),
              title: thisMenu.title,
              menu_item_parent: thisMenu.menu_item_parent,
              childs:
                thisMenu.child_items &&
                thisMenu.child_items.map((ele) => {
                  return {
                    link: ele.url.replace(regexWebsite, ""),
                    title: ele.title,
                    childs: ele.child_items,
                    menu_item_parent: ele.menu_item_parent,
                    id: ele.ID,
                  };
                }),
              id: thisMenu.ID,
            };
          }),
      };
      if (isLocalStorage) localStorage.menus = JSON.stringify(newState);
      return newState;
    default:
      return state;
  }
};
