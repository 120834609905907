import React, { Component } from 'react';
import Title from '../../Title';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from 'reactstrap';
import './styles.scss';

export default class CenteredText extends Component {
  render() {
    // const { steps, currentStep } = this.state;
    const { content, first } = this.props;
    let columnSize = { size: 7, offset: 0 };
    let smallColumnSize = { size: 7, offset: 0 };
    if (this.props.content.align === 'center') {
      columnSize = { size: 8, offset: 2 };
      smallColumnSize = { size: 8, offset: 2 };
    }
    return (
      <div
        className={`center-text ${this.props.content.align} ${
          content.background_colour ? 'colored' : ''
        }`}
        style={{ backgroundColor: content.background_colour }}
      >
        <Container>
          <Row>
            <Col xs="12" md={smallColumnSize} lg={columnSize}>
              <ScrollAnimation
                initiallyVisible={first}
                animateOnce={true}
                // animateOnce={first}
                animateIn="fadeInUp"
              >
                {content.sub_title && (
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: content.sub_title,
                    }}
                  />
                )}
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                initiallyVisible={first}
                delay={200}
              >
                {content.title && (
                  <h3 dangerouslySetInnerHTML={{ __html: content.title }} />
                )}
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                initiallyVisible={first}
                animateOnce={true}
                // animateOnce={first}
                delay={300}
              >
                <span className={'line-spacer'}></span>
                {content.content && (
                  <div
                    className={'content'}
                    dangerouslySetInnerHTML={{ __html: content.content }}
                  />
                )}
              </ScrollAnimation>
            </Col>
          </Row>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
