import React, { Component } from 'react';
import { Container, Row, Col, Collapse } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';

import './styles.scss';

export default class LayoutContentAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse(i) {
    this.setState({ selected: i });
  }

  render() {
    return (
      <Container className={'layout-content-accordion'}>
        <Row>
          <Col md={12} xs={12}>
            {this.props.content &&
              this.props.content.items.map((item, index) => (
                <ScrollAnimation
                  key={index}
                  animateIn="fadeInUp"
                  delay={index * 30}
                  initiallyVisible={this.props.first}
                  animateOnce={true}
                  // animateOnce={this.props.first}
                >
                  <div
                    className={`${
                      this.state.selected === index ? 'active' : 'notactive'
                    } item colapse`}
                  >
                    <p onClick={() => this.toggleCollapse(index)}>
                      <span>{item.title}</span>
                    </p>
                    <Collapse
                      className={'content'}
                      isOpen={this.state.selected === index}
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                  </div>
                </ScrollAnimation>
              ))}
          </Col>
        </Row>
      </Container>
    );
  }
}
