import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { scrollToItem } from "../../../utils/scroll";
import Language from "../../../containers/Language";
import "./styles.scss";
import defaultLogo from "../../../assets/logo_white.svg";
import config from "../../../../config";
import { stack as Menu } from "react-burger-menu";
import Search from "../../Search";

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      sub1: null,
      main: null,
    };
    this.setNewActive = this.setNewActive.bind(this);
    this.isMenuOpen = this.isMenuOpen.bind(this);
    this.closeAllMenus = this.closeAllMenus.bind(this);
  }

  scrollToMenu(link) {
    if (typeof window !== "undefined") {
      const item = document.getElementById(link);
      const wrapper = document.getElementsByClassName("bm-menu");

      wrapper[0].scrollTop = 300;
    }
  }

  setNewActive(index, type) {
    if (this.state[type] === index) {
      // this.setState({ [type]: null });
    } else {
      this.setState({ [type]: index });
    }
  }

  closeAllMenus() {
    this.setState({ isOpen: false });
  }

  isMenuOpen(state) {
    this.setState(state);
  }

  processCatLink(link, i) {
    let thisChildren = [];

    if (!link && this.props.categories["products"]) {
      thisChildren = this.props.categories["products"].filter(
        (cat) => cat.parent === 0 || !cat.parent
      );
    } else if (link) {
      thisChildren = this.props.categories["products"].filter(
        (cat) => cat.parent === link.id
      );
    }
    if (!thisChildren) return null;

    return (
      <ul className={`submenu_${i}`}>
        <Container>
          {thisChildren.map((item, index) => {
            return (
              <li
                key={index}
                id={`submenu_${i}_${index}`}
                className={`${
                  this.state.sub1 === index ? "activeSub" : ""
                } fadeIn animated ${
                  !item.childs && !item.child_items ? "notaParent" : ""
                }`}
                onClick={() => {
                  this.scrollToMenu(`submenu_${i}_${index}`);
                  this.setNewActive(index, "sub1");
                }}
                style={{
                  animationDelay: `${index / 6 + 0.01}s`,
                  animationDuration: `.6s`,
                }}
              >
                {this.renderLink(
                  item,
                  i,
                  i === 1 && (index === 1 || index === 0)
                )}
                {this.processCatLink(item, i + 1)}
              </li>
            );
          })}
        </Container>
      </ul>
    );
  }

  processChildLink(link, i) {
    // yap its not consistent, lets normalize
    if (!link.childs && !link.child_items) return null;

    const thisChildren = link.childs ? link.childs : link.child_items;
    return (
      <ul className={`submenu_${i}`}>
        {thisChildren.map((item, index) => {
          return (
            <li
              key={index}
              id={`submenu_${i}_${index}`}
              className={`${
                this.state.sub1 === index ? "activeSub" : ""
              } fadeIn animated ${
                !item.childs && !item.child_items ? "notaParent" : ""
              }`}
              onClick={() => {
                this.scrollToMenu(`submenu_${i}_${index}`);
                this.setNewActive(index, "sub1");
              }}
              style={{
                animationDelay: `${index / 6 + 0.01}s`,
                animationDuration: `.6s`,
              }}
            >
              {this.renderLink(item, i)}
              {this.processChildLink(item, i + 1)}
            </li>
          );
        })}
      </ul>
    );
  }

  renderLink(col, level, nolink) {
    if (!col && !col.link && !col.url) return null;
    const link = col.link || col.url;
    const regexWebsite = new RegExp(config.websiteURL);
    const realLink = link && link.replace(regexWebsite, "");
    //this sub menus are not acessible
    if (realLink && realLink[0] === "/" && !nolink) {
      return (
        <Link
          to={realLink}
          className={this.props.location.match(link) ? "active" : "notactive"}
          onClick={this.closeAllMenus}
          dangerouslySetInnerHTML={{ __html: col.title || col.name }}
        />
      );
    } else if (realLink && realLink !== "#" && !nolink) {
      return (
        <a
          href={realLink}
          className={this.props.location.match(link) ? "active" : "notactive"}
          onClick={this.closeAllMenus}
          // onClick={() => scrollToMenu(link)}
          dangerouslySetInnerHTML={{ __html: col.title || col.name }}
        />
      );
    } else {
      return (
        <span
          className={
            link && this.props.location.match(link) ? "active" : "notactive"
          }
          // onClick={() => scrollToMenu(link)}
          dangerouslySetInnerHTML={{ __html: col.title || col.name }}
        />
      );
    }
  }

  render() {
    let logoImageUrl =
      this.props.options &&
      this.props.options.white_logo &&
      this.props.options.white_logo.url
        ? this.props.options.white_logo.url
        : defaultLogo;
    // if (this.props.fixed && this.props.options && this.props.options.white_logo)
    //   logoImageUrl = this.props.options.white_logo.url;
    return (
      <div className={`wrap-mobile ${this.state.isOpen ? "open" : "closed"}`}>
        <div className={"logo"}>
          <Link
            to={"/"}
            style={
              logoImageUrl && {
                backgroundImage: `url('${logoImageUrl}')`,
              }
            }
          />
          <div className={"cross"} onClick={this.closeAllMenus}>
            +
          </div>
        </div>
        <Menu
          right
          width={"100%"}
          isOpen={this.state.isOpen}
          customCrossIcon={false}
          onStateChange={this.isMenuOpen}
        >
          <div className={"top"}>
            <ul>
              {this.props.content &&
                this.props.content.map((col, index) => {
                  return (
                    col.menu_item_parent === "0" && (
                      <li
                        key={index}
                        className={`${
                          this.state.main === `${index}` ? "activeMain" : ""
                        } fadeIn animated `}
                        onClick={() => this.setNewActive(`${index}`, "main")}
                        style={{
                          animationDelay: `${index - 0.5 + 0.02}s`,
                          animationDuration: `2s`,
                        }}
                      >
                        {this.renderLink(col)}
                        {col.title === "Produtos"
                          ? !this.state.hideSub && this.processCatLink(null, 1)
                          : !this.state.hideSub &&
                            this.processChildLink(col, 1)}
                      </li>
                    )
                  );
                })}
            </ul>
          </div>
          <div className={"bottom"}>
            <div className={"search-wrapp"}>
              <Search closeMenu={this.closeAllMenus} />
            </div>
            <Language closeMenu={this.closeAllMenus} lang={this.props.lang} />
          </div>
        </Menu>
      </div>
    );
  }
}

export default MobileMenu;
