import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import ReadMore from '../../../ReadMore';
import './styles.css';

function LayoutAlternateImageBackground(props) {
  return (
    <div id={props.id} className={'horizontal-image-background'}>
      <div
        className={'background-image'}
        style={{
          backgroundImage: `url('${props.content.image.sizes.large}')`,
        }}
      >
        <Container>
          <Row>
            {props.odd === 1 && <Col xs="12" md="7" />}

            <Col xs="12" md="5">
              <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
                <div className={'content'}>
                  <h5
                    dangerouslySetInnerHTML={{ __html: props.content.title }}
                  />
                  <p
                    dangerouslySetInnerHTML={{ __html: props.content.content }}
                  />
                  {props.content.link && (
                    <ReadMore link={props.content.link}>
                      {props.content.button_text}
                    </ReadMore>
                  )}
                </div>
              </ScrollAnimation>
            </Col>
            {!props.odd && <Col xs="12" md="7" />}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default LayoutAlternateImageBackground;
