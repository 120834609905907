import React, { Component } from 'react';

// Menus
// import LoadButton from '../Menus/LoadButton';
import Pager from '../Menus/Pager';
import Loading from '../Loading/';
import Categories from '../Menus/Categories';

// templates blocks
import Blocks from '../Blocks';

// list templates
import Lists from '../Lists';

export default class DynamicContent extends Component {
  constructor(props) {
    super(props);
  }

  renderContent(component, content, i, language, status) {
    let defaultProps = { key: i, first: i === 0, content, odd: i % 2 };
    if (!component || !content) {
      return null;
    } else if (component.toLowerCase().match('_list') && Lists[component]) {
      const ListToRender = Lists[component];
      return (
        <div key={i}>
          {content.show_categories &&
            content.categories &&
            content.categories.length > 0 && (
              <Categories
                first={i === 0}
                currentCategory={this.props.category}
                content={content.categories}
                contentType={content.content_type}
                language={language}
              />
            )}
          <ListToRender
            content={content}
            currentCategory={this.props.category}
            currentCategories={this.props.currentCategories}
            categories={this.props.categories}
            noResults={this.props.noResults}
            first={i === 0 && !content.show_categories}
            status={status}
          />
          {/* <LoadButton
            status={status}
            content={content}
            loadMoreContentPage={this.props.loadMoreContentPage}
          /> */}
          <Pager
            status={status}
            content={content}
            loadMoreContentPage={this.props.loadMoreContentPage}
          />
        </div>
      );
    } else if (Blocks[component]) {
      const ElementoToRender = Blocks[component];
      if(component==='Manuais'){
        defaultProps = {
          ...defaultProps,
          categories: this.props.categories,
        };
      }

      return <ElementoToRender {...defaultProps} />;
    }
  }

  processContent() {
    const { content, status, language } = this.props;
    // Exit if no content
    if (
      !content ||
      !content.acf ||
      !content.acf.content ||
      content.acf.content.length === 0
    )
      return <Loading />;
    return content.acf.content.map((cont, i) => {
      return this.renderContent(
        cont.acf_fc_layout,
        cont.fields,
        i,
        language,
        status
      );
    });
  }

  render() {
    const { content } = this.props;
    return content && <div className={'content'}>{this.processContent()}</div>;
  }
}
