import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import Modal from "react-responsive-modal";

import Button from "../../Button";
import "./styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

export default class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      photoIndex: 0,
      buyingOptionsModal: false,
    };
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.setState({
      buyingOptionsModal: false,
    });
  }

  render() {
    const { content, first } = this.props;
    const photoIndex = this.state.photoIndex;
    const buyingOptions = this.props.content.buyingOptions;
    const images = content.images;
    const settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className={"product-details"}>
        <Container>
          <Row>
            <Col
              xs="12"
              md="6"
              className={
                "product-text-section align-items-center align-self-center"
              }
            >
              <ScrollAnimation
                initiallyVisible={first}
                // animateOnce={first}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <h2 dangerouslySetInnerHTML={{ __html: content.title }} />
                <p className={"reference"}>Ref: {content.sku}</p>
                <div
                  className={"product-text"}
                  dangerouslySetInnerHTML={{ __html: content.text }}
                />

                {/* {content.energy && (
                  <div
                    className={`energy ${content.energy.replace(/\+/g, "_")}`}
                  >
                    <span>{content.energy}</span>
                  </div>
                )} */}
                {buyingOptions && buyingOptions.length > 0 && (
                  <Button
                    onClick={() =>
                      this.setState({
                        buyingOptionsModal: true,
                      })
                    }
                  >
                    {"Comprar"}
                  </Button>
                )}
              </ScrollAnimation>
            </Col>
            <Col
              xs="12"
              md="6"
              className={
                "product-gallery-section align-items-center align-self-center"
              }
            >
              <ScrollAnimation
                initiallyVisible={first}
                // animateOnce={first}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <div className={"gallery-container"}>
                  <Slider {...settings}>
                    {images &&
                      images.map((slide, index) => {
                        return (
                          <div key={index}>
                            <div
                              className={"slide"}
                              onClick={() =>
                                this.setState({
                                  isOpen: true,
                                  photoIndex: index,
                                })
                              }
                              style={{
                                backgroundImage: `url('${slide.image}')`,
                              }}
                            />
                          </div>
                        );
                      })}
                  </Slider>
                  <div className={"markers"}>
                    {content.marker &&
                      content.marker.map((mark, i) => {
                        if (
                          mark.acf &&
                          mark.acf.excerpt &&
                          mark.acf.excerpt.image
                        ) {
                          return (
                            <div
                              key={i}
                              className={"marker"}
                              style={{
                                backgroundImage: `url(${mark.acf.excerpt.image.url})`,
                              }}
                            />
                          );
                        }
                      })}
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
        {this.state.isOpen && (
          <Lightbox
            mainSrc={`${images[photoIndex].image}`}
            imageCaption={``}
            nextSrc={`${images[(photoIndex + 1) % images.length].image}`}
            prevSrc={`${images[(photoIndex + images.length - 1) % images.length].image}`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}

        {buyingOptions && buyingOptions.length > 0 && (
          <Modal
            center
            open={this.state.buyingOptionsModal}
            onClose={this.onCloseModal}
            classNames={{
              modal: "custom-buying-list",
              overlay: "custom-overlay",
            }}
          >
            <div className={"buy-options-wrap"}>
              <h3>Online</h3>
              {buyingOptions.map((option, i) => {
                return (
                  <a
                    className={"buy-options-link"}
                    key={i}
                    href={option.link}
                    target={"_blank"}
                  >
                    {option.title}
                  </a>
                );
              })}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
