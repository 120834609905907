import React from 'react';
import LayoutAlternateContent from '../../Blocks/OLD/LayoutAlternateContent';
import './styles.css';
import config from '../../../../config';

function LayoutListContentAlternate(props) {
  const content = props.content;

  if (!content || !content.content) return <div />;

  return content.content.map((cont, i) => {
    if (!cont.acf.excerpt) return false;
    return (
      <div key={i} className={'content-list-alternate '}>
        <LayoutAlternateContent
          odd={i % 2}
          first={i === 0}
          content={{
            title: cont.title.rendered,
            content: cont.acf.excerpt.summary,
            image: cont.acf.excerpt.image,
            link: cont.link.replace(config.websiteURL, ''),
            button_text: content.button_text,
          }}
        />
      </div>
    );
  });
}

export default LayoutListContentAlternate;
