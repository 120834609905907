import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './styles.scss';
import ReactPlayer from 'react-player';

function AlternateContent(props) {
  function renderVisual() {
    return props.content.type === 'video' ? renderVideo() : renderImage();
  }
  function renderVideo() {
    return (
      <Col xs="12" md="6" className={'align-items-center align-self-center'}>
        <ReactPlayer url={props.content.video} width={'100%'} />
      </Col>
    );
  }
  function renderImage() {
    return (
      <Col xs="12" md="6" className={'align-items-center align-self-center '}>
        <ScrollAnimation
          initiallyVisible={props.first}
          animateOnce={true}
          // animateOnce={props.first}
          animateIn="fadeIn"
        >
          <div
            className={
              props.content.image || props.content.image.src
                ? 'image'
                : 'noimage'
            }
          >
            {(props.content.image || props.content.image.src) && (
              <img
                src={props.content.image.url || props.content.image.src}
                alt={props.content.image.alt}
              />
            )}
          </div>
        </ScrollAnimation>
      </Col>
    );
  }

  function renderText() {
    return (
      <div className={'content-wrap'}>
        <ScrollAnimation
          initiallyVisible={props.first}
          animateOnce={true}
          // animateOnce={props.first}
          animateIn="fadeIn"
        >
          {props.content.sub_title && (
            <h6
              dangerouslySetInnerHTML={{
                __html: props.content.sub_title,
              }}
            />
          )}
          <h5 dangerouslySetInnerHTML={{ __html: props.content.title }} />

          <div dangerouslySetInnerHTML={{ __html: props.content.content }} />
        </ScrollAnimation>
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className={`alternate-content ${props.odd === 1 ? 'odd' : 'even'} ${
        props.first ? 'first' : ''
      } `}
      style={{ backgroundColor: props.content.background_colour }}
    >
      <Container>
        <Row>
          {!props.odd && renderVisual()}
          <Col
            xs="12"
            md="6"
            className={'content align-items-center align-self-center'}
          >
            {!props.first && renderText()}
          </Col>
          {props.odd === 1 && renderVisual()}
        </Row>
      </Container>
    </div>
  );
}

export default AlternateContent;
