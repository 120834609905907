import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { loadingBarMiddleware } from "react-redux-loading-bar";

import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import createRootReducer from "./reducers";

// Create a history depending on the environment
const history = createBrowserHistory();

const enhancers = [];

// Dev tools are helpful
if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const middleware = [
  thunk,
  routerMiddleware(history),
  loadingBarMiddleware({
    promiseTypeSuffixes: ["REQUEST", "SUCCESS", "FAILURE"],
  }),
];
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

// Do we have preloaded state available? Great, save it.
const initialState = {};

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

// Create Redux store with initial state
const store = createStore(
  createRootReducer(history),
  preloadedState || initialState,
  composedEnhancers
);

// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState(),
});

export { store, history };
