import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Title from "../../Title";
import axios from "axios";
import config from "../../../../config";

import "./styles.scss";

export default class ContentPager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      status: "",
      list: [],
    };
  }

  componentDidMount() {
    if (this.props?.content?.sku) this.searchKeyword(this.props?.content?.sku);
  }

  searchKeyword(key) {
    this.setState({ status: "loading" });
    // no content saved lets fecth it fromjson
    var params = new URLSearchParams();
    const keyword = key || this.state.keyword;
    params.append("keyword", keyword.toUpperCase());

    axios
      .post(`${config.apiURL}/wp-json/manuais/v1/get`, params)
      .then((result) => {
        console.log(result, params);
        if (result.data.status === "OK") {
          this.setState({ status: "success", list: result.data.msg || [] });
        } else {
          this.setState({ status: "error" });
        }
      })
      .catch((error) => {
        this.setState({ status: "error" });
      });
  }

  renderList() {
    return (
      <ul>
        {this.state.list.map((item, i) => {
          return (
            <li>
              <a
                href={`${config.apiURL}/wp-content/uploads/manuais/${item}`}
                target="_blank"
                className={`clearfix`}
              >
                <span className={`pdf`} />
                <span>{item.toLowerCase()}</span>
                {/* <span>{this.cleanFileName(item.toLowerCase())}</span> */}
                <span className={`download`} />
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const content = this.props.content.item;
    const first = this.props.first;
    if (!content) return null;
    let slide = content.find((slide, index) => index === this.state.slideIndex);
    if (!slide) return null;
    return (
      <div className={"contents-list-pager"}>
        <Container>
          <ScrollAnimation
            initiallyVisible={first}
            animateOnce={true}
            // animateOnce={first}
            animateIn="fadeInUp"
          >
            <div className={"pager"}>
              {content.map((thisSlide, index) => {
                if (
                  this.state?.list?.length === 0 &&
                  thisSlide.title === "Documentação"
                )
                  return null;

                return (
                  thisSlide.title && (
                    <div
                      key={index}
                      onMouseOver={(e) => this.setState({ slideIndex: index })}
                      className={`item ${
                        index === this.state.slideIndex ? "active" : ""
                      }`}
                    >
                      <p
                        dangerouslySetInnerHTML={{ __html: thisSlide.title }}
                      />
                    </div>
                  )
                );
              })}
            </div>
          </ScrollAnimation>
        </Container>
        <div className={"content-wrapper"}>
          <Container>
            <Row>
              <Col md={{ size: 10, offset: 1 }}>
                <ScrollAnimation
                  initiallyVisible={first}
                  animateOnce={first}
                  animateIn="fadeIn"
                >
                  <div className={"slide clearfix"}>
                    <div className={"content"}>
                      <h3>{slide.sub_title}</h3>
                      <div
                        style={{
                          display:
                            slide.title === "Características"
                              ? "block"
                              : "none",
                        }}
                      >
                        <ul className={"chars"}>
                          {slide.title === "Características" &&
                            slide?.content?.map((item, index) => {
                              if (!item.feature || item.feature.id == "2803")
                                return null;
                              return (
                                <li key={index}>
                                  <span className={"title"}>
                                    {item.feature.description}
                                  </span>

                                  <span className={"value"}>
                                    {item.feature.value}
                                  </span>
                                </li>
                              );
                            })}{" "}
                        </ul>
                      </div>
                      <div
                        className="manuais-list"
                        style={{
                          display:
                            slide.title === "Documentação" ? "block" : "none",
                        }}
                      >
                        {this.renderList()}
                      </div>
                      {slide.title !== "Documentação" &&
                        slide.title !== "Características" && (
                          <div
                            dangerouslySetInnerHTML={{ __html: slide.content }}
                          />
                        )}
                    </div>
                  </div>
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
