export default {
  apiURL: "https://becken.cors.digital",
  websiteURL: "https://beckenhome.com",
  languages: ["pt"],
  listSize: 9,
  defaultLang: "pt",
  axiosDevHeader: {
    consumerKey: "",
    consumerSecret: "",
  },
};
