import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import config from '../../config';

import NoContent from '../components/NoContent';
import Content from './content';
import Products from './products';

const languages = config.languages.join('|');
export default () => (
  <Switch>
    <Route
      exact
      path={`/:lang(${languages})?/NotFound`}
      component={NoContent}
    />
    <Redirect
      from={`/:lang(${languages})?/homepage`}
      to={`/:lang(${languages})?/`}
    />

    <Route exact path={`/:lang(${languages})?/:content?`} component={Content} />
    <Route path={`/:lang(${languages})?/search`} component={Content} />

    <Route
      exact
      path={`/:lang(${languages})?/products/:content`}
      component={Products}
    />
    <Route
      exact
      path={`/:lang(${languages})?/:type/:content?`}
      component={Content}
    />

    <Route
      path={`/:lang(${languages})?/:type/:category/:subcategory?/:thirdcategory?`}
      component={Content}
    />

    <Route exact path={`*`} component={NoContent} />
  </Switch>
);
