import config from "../../../config";
import * as constants from "./constants";
import { dataFetch } from "../Fetch";
import { store } from "../../../store";
import { getSystemInitPure } from "../System/actions";
/**
 *  if we have a category defined we will need to request the id before anything else
 */
const generateProductDetails = async (dispatch, content, lang) => {
  dispatch({
    type: constants.SET_RELATED_PRODUCTS_REQUEST,
  });

  let productSpecs = store.getState().options.productSpecs;
  // let currentCat = store.getState().content.currentCat;
  /// lets genereate breadcrums
  const breadcrumbs = generateBreadCrumbs(
    [content],
    undefined,
    undefined,
    dispatch
  );
  const currentCat = breadcrumbs[breadcrumbs.length - 2] || {};

  //system not loaded yet lets force it
  if (productSpecs.length === 0) {
    const system = await getSystemInitPure(lang, dispatch);
    productSpecs = system && system.data.options.acf.content.product_details;
  }
  let contentWithMarker = [content];
  if (content.marker) {
    contentWithMarker = addMarkersToContent(contentWithMarker);
  }
  const eneryEfficiency =
    content.acf &&
    content.acf.specs &&
    content.acf.specs.find((spec) => spec.feature && spec.feature.id == 133);

  console.log(productSpecs, "productSpecs");
  let dynamicContent = {
    id: content.id,
    acf: {
      content: [
        {
          acf_fc_layout: "ProductDetails",
          fields: {
            sku: content.acf.details.sku,
            title: content.title.rendered,
            text: content.acf.details.description,
            marker: contentWithMarker[0].marker,
            buyingOptions: content.acf.buying_options,
            energy:
              eneryEfficiency &&
              eneryEfficiency.feature &&
              eneryEfficiency.feature.value,
            images: [
              {
                image: `${config.apiURL}/wp-content/uploads/products/${content.acf.details.sku}.jpg`,
              },
              ...(content.acf.gallery
                ? content.acf.gallery.map((cont) => {
                    return {
                      image: `${config.apiURL}/wp-content/uploads/products/${cont.image}`,
                    };
                  })
                : []),
              // {
              //   image: `${config.apiURL}/wp-content/uploads/products/${content.acf.details.sku}.jpg`,
              // },
            ],
          },
        },
        {
          acf_fc_layout: "ContentPager",
          fields: {
            sku: content.acf.details.sku,
            item:
              productSpecs &&
              productSpecs.map((ele, i) => {
                let contentOutput = "";
                if (i === 0) {
                  contentOutput = content.acf.specs;
                  return {
                    title: ele.name,
                    sub_title: ele.title,
                    content: contentOutput,
                  };
                }
                if (i === 1) {
                  contentOutput = content.acf.details.description;
                  return {
                    title: ele.name,
                    sub_title: ele.title,
                    content: contentOutput,
                  };
                }
                if (i === 2) {
                  contentOutput = content.acf.details.description;
                  return {
                    title: ele.name,
                    sub_title: ele.title,
                    content: contentOutput,
                  };
                }
                return {};
              }),
          },
        },
        {
          acf_fc_layout: "Media",
          fields: {
            background_colour: "#ffffff",
            height: "500",
            tipo: "video",
            video: content.acf.video || "",
          },
        },
        {
          acf_fc_layout: "ContentGridSlideshow_List",
          fields: {
            button_text: "Saiba Mais",
            category: [currentCat.id] || [],
            content_type: "products",
            number_of_items: "9",
            show_categories: false,
            title: "Produtos Relacionados",
          },
        },
      ],
    },
  };

  const updatedContent = await getListContent(
    dynamicContent,
    lang || config.defaultLang,
    [currentCat.id] || content.categories
  );
  dispatch({
    type: constants.SET_RELATED_PRODUCTS,
    content: updatedContent,
  });
};

/**
 *  generate breadcrumbs
 */
export const generateBreadCrumbs = (
  content,
  type = "products",
  currentCategory,
  dispatch
) => {
  let breadcrumbs = [];
  let breadcrumbsData = [];
  let breadcrumbsOrder = [];

  const categories = store.getState().content.categories;
  if ((!content || !content[0] || !content[0].categories) && !currentCategory) {
    dispatch({
      type: constants.SET_BREADCRUMBS,
      breadcrumbs: [],
    });
    return null;
  }

  // here lets fetch all cats we are working with
  const getCatsByOrder = (cat) => {
    breadcrumbsData =
      breadcrumbs &&
      breadcrumbs.map((col, index) => {
        const currentCat =
          categories[type] && categories[type].find((cat) => cat.id === col);
        if (currentCat && currentCat.parent === 0)
          breadcrumbsOrder.push(currentCat);
        return currentCat;
      });
    recursiveFetchCategory(breadcrumbsOrder[0]);
  };

  // recursive fetch parent cat
  const recursiveFetchCategory = (cat) => {
    if (!cat) return null;

    const currentCat = breadcrumbsData.find((bcat) => bcat.parent === cat.id);
    // if we find lets go to next step
    if (currentCat) {
      breadcrumbsOrder.push(currentCat);
      recursiveFetchCategory(currentCat);
    }
  };

  const getAllParentCategories = (cat) => {
    if (!categories[type]) return null;
    const currentCat = categories[type].find((bcat) => bcat.id === cat.parent);
    if (currentCat) {
      breadcrumbsOrder.push(currentCat);
      getAllParentCategories(currentCat);
    }
  };

  //now we can start recursive fetch order
  if (content && content[0].categories) {
    breadcrumbs = content[0].categories;
    getCatsByOrder();
  } else if (currentCategory && currentCategory.length > 0) {
    // we need to fetch all parent cats
    breadcrumbsOrder.push(currentCategory[0]);
    getAllParentCategories(currentCategory[0]);
    breadcrumbsOrder.reverse();
  }

  dispatch({
    type: constants.SET_BREADCRUMBS,
    breadcrumbs: breadcrumbsOrder,
  });

  return breadcrumbsOrder || [];
};

/**
 *  if we have a category defined we will need to request the id before anything else
 */
export const getAllCategories = (type, lang) => async (dispatch) => {
  const fetchCategoryData = await getCategories(type, lang);
  dispatch({
    type: constants.GET_CATEGORIES,
    categories: { name: `${type}`, data: fetchCategoryData.data },
  });
};

/**
 *  we need to add the marker to the content
 */
export const addMarkersToContent = (content) => {
  let markers = store.getState().options.markers;
  return content.map((cont) => {
    if (cont.marker && cont.marker.length > 0) {
      cont.marker = cont.marker.map((mark) => {
        return markers.find((m) => m.id == mark);
      });
    }
    return cont;
  });
};

/**
 *  if we have a category defined we will need to request the id before anything else
 */
const getCategories = (type, lang) => {
  try {
    if (type === "products") {
      return dataFetch(
        type,
        `categories?lang=${lang}&per_page=200&status=publish`
      );
    }
    // lets get category if we need
    return dataFetch(type, `${type}_types?lang=${lang}&status=publish`);
  } catch (error) {
    console.log(error);
  }
};

/**
 *  if we have a category defined we will need to request the id before anything else
 */
const getMarker = (lang) => {
  try {
    return dataFetch(`marker?lang=${lang}&per_page=99&status=publish`);
  } catch (error) {
    console.log(error);
  }
};

/*
/ this gets more content for list
*/

export const getMoreContentPage = (
  pageNumber = 1,
  lang = config.defaultLang,
  type = "pages",
  number = config.listSize,
  category = null,
  search
) => async (dispatch) => {
  //http://localhost:8080/wp-json/wp/v2/landingpages?lang=de
  dispatch({
    type: constants.GET_MORE_CONTENT_REQUEST,
  });

  let getUrl = `${type}?lang=${lang}&per_page=${number}&page=${pageNumber}`;
  let getType = type;

  const categoriesString = category && category.map((c) => c.id).join(",");

  if (category) {
    getUrl = `${type}?${type}_types=${categoriesString}&lang=${lang}&per_page=${number}&page=${pageNumber}`;
  }

  if (category && type === "products") {
    getUrl = `${type}/?categories=${categoriesString}&lang=${lang}&per_page=${number}&page=${pageNumber}`;
  }

  if (search) {
    getUrl = `products?search=${search
      .split(" ")
      .join(
        "+"
      )}&lang=${lang}&status=publish&per_page=${number}&page=${pageNumber}`;
  }

  const response = await dataFetch(getType, `${getUrl}&status=publish`);

  dispatch({
    type: constants.GET_MORE_CONTENT,
    content: {
      content: response.data,
      page: pageNumber,
      type,
    },
  });
};

/*
/ this gets the content for all list items
*/

export const getListContent = async (
  response,
  lang,
  categoryFound,
  gotCategories,
  search
) => {
  let contentResponse = response;
  // first check if we have contetn
  if (
    (!response || !response.acf || !response.acf.content) &&
    (!response.data ||
      !response.data[0] ||
      !response.data[0].acf ||
      !response.data[0].acf.content)
  ) {
    return response;
  } else if (
    response.data &&
    response.data[0] &&
    response.data[0].acf.content
  ) {
    contentResponse = response.data[0];
  }
  // loop through content to find list items
  const content = contentResponse.acf.content.map(async (ele) => {
    if (
      ele.acf_fc_layout === "ContentGridSlideshow_List" ||
      ele.acf_fc_layout === "ContentGridHighlight_List" ||
      ele.acf_fc_layout === "content_list_alternate" ||
      ele.acf_fc_layout === "content_list_highlight" ||
      ele.acf_fc_layout === "content_list_map" ||
      ele.acf_fc_layout === "content_list_gallery" ||
      ele.acf_fc_layout === "content_list_text_slider" ||
      ele.acf_fc_layout === "content_list_horizontal_image" ||
      ele.acf_fc_layout === "categories_list_alternate" ||
      ele.acf_fc_layout === "ContentGrid_List"
    ) {
      const type = ele.fields.content_type; // content type
      const number = ele.fields.number_of_items || config.listSize; // content type
      const showCategories = ele.fields.show_categories; // content type
      let showOneCategory = ele.fields.category; // content type
      let showOneMarker = ele.fields.show_marker; // content type

      showOneCategory = Array.isArray(showOneCategory)
        ? showOneCategory.map((c) => c.id || c).join(",")
        : showOneCategory;

      // showOneMarker = Array.isArray(showOneMarker)
      //   ? showOneMarker.map((c) => c.id || c).join(",")
      //   : showOneMarker;

      let fetchCategoryData = [];
      let fetchMarkerData = [];
      if (showCategories) {
        // lets get category if we need
        let catURl = `${type}_types?lang=${lang}`;

        if (showOneCategory) {
          catURl = `${type}_types/${showOneCategory}?lang=${lang}`;
        }

        fetchCategoryData = !gotCategories.data
          ? await dataFetch(type, `${catURl}&status=publish`)
          : gotCategories;
      }

      if (showOneMarker) {
        let markers = store.getState().options.markers;
        fetchMarkerData = markers;

        if (!markers || markers.length < 1) {
          const dataMaker = await dataFetch(
            type,
            `marker?status=publish&lang=${lang}`
          );
          fetchMarkerData = dataMaker.data;
        }
      }

      //http://localhost:8085/wp-json/wp/v2/inspirations?inspirations_types=22
      // if we have a category selected we need to fech only posts from that category
      let getUrl = `${type}?lang=${lang}&per_page=${number}`;
      let getType = type;
      let fetchData;

      if (search) {
        getUrl = `products?search=${search
          .split(" ")
          .join("+")}&lang=${lang}&status=publish`;
        getType = `products`;
      }
      const categoriesString = categoryFound
        ? categoryFound.map((c) => c.id || c).join(",")
        : showOneCategory;

      // if (!markers || markers.length < 1) {
      //   const markersData = await getMarker(type, lang);
      //   markers = markersData.data;
      // }

      const markersString = showOneMarker
        ? fetchMarkerData
            .map((c) => c.id || c)
            .filter((mark) => showOneMarker.includes(mark))
            .join(",")
        : showOneMarker;

      if (categoryFound) {
        getUrl = `${type}?${type}_types=${categoriesString}&lang=${lang}&per_page=${number}`;
      }

      if (categoriesString && type === "products") {
        getUrl = `${type}/?categories=${categoriesString}&lang=${lang}&per_page=${number}`;
      }
      if (markersString && type === "products") {
        getUrl = `${type}/?marker=${markersString}&lang=${lang}&per_page=${number}`;
      }

      // lets get the content for the list now
      fetchData = await dataFetch(getType, `${getUrl}&status=publish`);

      //lets add custom markers to products
      if (
        fetchData &&
        fetchData.data &&
        fetchData.data.length > 0 &&
        type === "products"
      ) {
        fetchData.data = addMarkersToContent(fetchData.data);
      }

      if (fetchData && fetchData.headers["x-wp-totalpages"]) {
        ele.fields.total = fetchData.headers["x-wp-total"];
        ele.fields.totalpages = fetchData.headers["x-wp-totalpages"];
      }

      // now lets update the content with the fectched list content
      if (fetchData && fetchData.data) {
        ele.fields.content = fetchData.data;
      }
      // now lets update the content with the fectched list content
      if (fetchCategoryData && fetchCategoryData.data) {
        ele.fields.categories = fetchCategoryData.data;
      }
    }
    return ele;
  });

  return Promise.all(content);
};

/*
/ this gets the content
*/

export const getContentPage = (
  slug = "homepage",
  lang = config.defaultLang,
  type = "pages",
  category = null,
  subCategory = null,
  thirdCategory = null,
  search
) => async (dispatch) => {
  //http://localhost:8080/wp-json/wp/v2/landingpages?lang=de
  dispatch({
    type: constants.SET_CURRENT_CONTENT_REQUEST,
    typePage: type,
  });

  let response = {};
  let fetchedData = {};
  let fetchCategoryData = {};
  let categoryFound = null;
  //lets get all cats
  const categoriesStore = store.getState().content.categories;
  if (!categoriesStore[type] && type !== "pages") {
    // fetch page content
    try {
      fetchCategoryData = await getCategories(type, lang);
      dispatch({
        type: constants.GET_CATEGORIES,
        categories: { name: `${type}`, data: fetchCategoryData.data },
      });
    } catch (error) {
      dispatch({
        type: constants.GET_CATEGORIES,
        categories: { name: `${type}`, data: [] },
      });
    }
  } else {
    fetchCategoryData = { data: categoriesStore[type] };
  }

  // if we have a category selected we need to fech only posts from that category
  if (category && !search) {
    // we should just look for the last category in the list
    const categoryToFetch = thirdCategory || subCategory || category;
    // left filter out the categories found
    categoryFound =
      fetchCategoryData.data &&
      fetchCategoryData.data.filter((cat) => cat.slug === categoryToFetch);
    // lets set the categories
    dispatch({
      type: constants.SET_CURRENT_CATEGORIES,
      category: categoryFound,
    });
  } else {
    dispatch({
      type: constants.SET_CURRENT_CATEGORIES,
      category: null,
    });
  }

  /**
   *  Now lets query on with our page request and pass in the cat id
   */
  let getUrl = `${type}?slug=${slug}&lang=${lang}`;
  let fetchType = type;

  if (categoryFound) {
    getUrl = `pages?slug=${type}&lang=${lang}`;
    fetchType = `pages`;
  }

  // fetch page content
  try {
    response = await dataFetch(fetchType, `${getUrl}&status=publish`);
  } catch (error) {
    console.log(error);
  }

  if (response.data) {
    generateBreadCrumbs(response.data, type, categoryFound, dispatch);
  } else {
    generateBreadCrumbs({}, type, categoryFound, dispatch);
  }

  try {
    /**
     *  Now lets teh content inside the category
     */
    fetchedData = await getListContent(
      { ...response },
      lang,
      categoryFound,
      fetchCategoryData,
      search
    );

    const currentContent = [
      {
        ...response.data[0],
        acf: {
          ...response.data[0].acf,
          content: fetchedData.data || fetchedData,
        },
      },
    ];
    // if (response.data.length < 1) throw new Error('no content');
    // setup correct output join list content with reply
    dispatch({
      type: constants.SET_CURRENT_CONTENT,
      content: currentContent,
    });

    // custom product type
    if (
      type === "products" &&
      currentContent[0].acf &&
      currentContent[0].acf.details
    )
      generateProductDetails(dispatch, currentContent[0], lang);
  } catch (error) {
    console.log(error);
    // if (response.data.length < 1) throw new Error('no content');
    // setup correct output join list content with reply
    if (response.data) {
      dispatch({
        type: constants.SET_CURRENT_CONTENT,
        content: [
          {
            ...response.data[0],
          },
        ],
      });
    }
  }
};

/*
/ clear all content for proper page reload
*/

export const removeCurrentContent = () => (dispatch) =>
  new Promise((resolve) => {
    // dispatch({
    //   type: constants.SET_CURRENT_CONTENT,
    //   content: [{ acf: {} }],
    // });

    resolve({});
  });
export const removeProductContent = () => (dispatch) => {
  dispatch({
    type: constants.SET_RELATED_PRODUCTS,
    content: [],
  });
  dispatch({
    type: constants.SET_CURRENT_CONTENT,
    content: [{ status: "unloading product" }],
  });
};
