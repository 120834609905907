import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from 'reactstrap';
import './styles.scss';

export default class LayoutContentColumns extends Component {
  render() {
    // const { steps, currentStep } = this.state;
    const { content, first } = this.props;
    return (
      <div className={'content-collumns'}>
        <Container>
          <ScrollAnimation
            initiallyVisible={first}
            animateOnce={true}
            // animateOnce={first}
            animateIn="fadeIn"
          >
            {content.title && <h4>{content.title}</h4>}
          </ScrollAnimation>
          <ScrollAnimation
            initiallyVisible={first}
            animateOnce={true}
            // animateOnce={first}
            animateIn="fadeInUp"
          >
            <div
              className={'content'}
              dangerouslySetInnerHTML={{ __html: content.content }}
            />
          </ScrollAnimation>
        </Container>
      </div>
    );
  }
}
