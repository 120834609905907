import React from 'react';
import { Link } from 'react-router-dom';
import Language from '../../../containers/Language';
import './styles.scss';
import Search from '../../Search';

function SubMenu(props) {
  return (
    <div className={'submenu clearfix'}>
      <div className={'search-wrapp'}>
        <Search />
      </div>
      <div className={'menu'}>
        <ul>
          {props.content.map((col, index) => (
            <li
              key={index}
              // className={'fadeIn animated'}
              style={{
                animationDelay: `${index + 0.05}s`,
                animationDuration: `.8s`,
              }}
            >
              <Link
                className={props.location === col.link ? 'active' : 'notactive'}
                to={col.link}
              >
                {col.title}
              </Link>
            </li>
          ))}
          <li>
            <Language language={props.lang} location={props.location} />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SubMenu;
