export const SET_RELATED_PRODUCTS_REQUEST = 'content/RELATED_PRODUCTS_REQUEST';
export const SET_RELATED_PRODUCTS = 'content/RELATED_PRODUCTS_SUCCESS';
export const SET_CURRENT_CONTENT = 'content/CURRENT_CONTENT_SUCCESS';
export const SET_CURRENT_CONTENT_REQUEST = 'content/CURRENT_CONTENT_REQUEST';
export const GET_MORE_CONTENT = 'content/MORE_CONTENT_SUCCESS';
export const GET_MORE_CONTENT_REQUEST = 'content/MORE_CONTENT_REQUEST';

export const GET_CATEGORIES_REQUEST = 'content/CATEGORIES_REQUEST ';
export const GET_CATEGORIES = 'content/CATEGORIES_SUCCESS';
export const SET_CURRENT_CATEGORIES = 'content/CURRENT_CATEGORIES_SUCCESS';

export const GET_CATEGORY_CONTENT = 'content/CATEGORY_CONTENT_REQUEST';
export const SET_CATEGORY_CONTENT = 'content/CATEGORY_CONTENT_SUCCESS';
export const SET_BREADCRUMBS = 'content/SET_BREADCRUMBS';
