import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./styles.scss";
import config from "../../../../config";

/**
 * here we are handling product pages breadcrums and category
 * so there a condition to fetch and order each use case
 */

function BreadCrumb(props) {
  const regexWebsite = new RegExp(config.websiteURL);
  if (!props.breadcrumbs || props.breadcrumbs.length < 1) return null;
  return (
    <div className={"breadcrumb-trail clearfix"}>
      <Container>
        <ul>
          {props.breadcrumbs.map((col, index) => {
            const realLink = col.link.replace(regexWebsite, "");

            return (
              <li
                key={index}
                className={"fadeIn animated"}
                style={{
                  animationDelay: `${index + 0.2}s`,
                  animationDuration: `2s`,
                }}
              >
                {index == 0 ? (
                  <span>{col.name}</span>
                ) : (
                  <Link
                    to={`${realLink}`}
                    className={
                      props.currentCategory === col.slug
                        ? "active"
                        : "notactive"
                    }
                  >
                    {col.name}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </Container>
    </div>
  );
}

export default BreadCrumb;
