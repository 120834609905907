import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './styles.scss';
import ReactPlayer from 'react-player';

function LayoutContent(props) {
  // no centent
  if (
    (props.content.tipo === 'video' &&
      (!props.content.video || props.content.video == '')) ||
    (props.content.tipo === 'image' &&
      (!props.content.image || props.content.image.src == ''))
  )
    return null;
  function renderVideo() {
    return (
      <Col
        xs="12"
        md="12"
        className={'video align-items-center align-self-center'}
      >
        <ReactPlayer
          url={props.content.video}
          height={props.content.height ? `${props.content.height}px` : '300px'}
          width={'100%'}
        />
      </Col>
    );
  }
  function renderImage() {
    return (
      <Col
        xs="12"
        md="12"
        className={'image align-items-center align-self-center'}
      >
        <div
          className={
            props.content.image || props.content.image.src
              ? 'imageDiv'
              : 'noimage'
          }
          style={{
            height: `${props.content.height}px`,
            backgroundImage: `url('${props.content.image.url}')`,
          }}
        ></div>
      </Col>
    );
  }
  return (
    <Container>
      <div id={props.id} className={`content-media ${props.first && 'first'}`}>
        <Row className={'media-wrap'}>
          {props.content.tipo === 'video' ? renderVideo() : renderImage()}
        </Row>
      </div>
    </Container>
  );
}

export default LayoutContent;
