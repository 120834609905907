import React from 'react';
import { Link } from 'react-router-dom';
import { scrollToItem } from '../../../utils/scroll';
import './styles.scss';
import defaultLogo from '../../../assets/logo_white.svg';
function FooterMenu(props) {
  function processChildLink(link) {
    if (!link.childs || link.childs.length < 1) return null;
    return (
      <ul className={'submenu-footer'}>
        {link.childs.map((item, index) => {
          return (
            <li
              key={index}
              className={'fadeIn animated'}
              style={{
                animationDelay: `${index / 6 + 0.01}s`,
                animationDuration: `.6s`,
              }}
            >
              {renderLink(item)}
            </li>
          );
        })}
      </ul>
    );
  }

  function renderLink(col) {
    if (col.link[0] === '/') {
      return (
        <Link
          to={col.link}
          className={props.location.match(col.link) ? 'active' : 'notactive'}
          dangerouslySetInnerHTML={{ __html: col.title }}
        />
      );
    } else {
      return (
        <span
          className={
            col.link && props.location.match(col.link) ? 'active' : 'notactive'
          }
          // onClick={() => scrollToReservation(link)}
          dangerouslySetInnerHTML={{ __html: col.title }}
        />
      );
    }
  }

  return (
    <ul className={'footer-menu  clearfix'}>
      <li className={'menu-items'}>
        <ul>
          {props.content &&
            props.content.map((col, index) => {
              return (
                col.menu_item_parent === '0' && (
                  <li
                    key={index}
                    className={'fadeIn animated'}
                    style={{
                      animationDelay: `${index - 0.5 + 0.02}s`,
                      animationDuration: `2s`,
                    }}
                  >
                    {renderLink(col)}
                    {processChildLink(col)}
                  </li>
                )
              );
            })}
        </ul>
      </li>
    </ul>
  );
}

export default FooterMenu;
