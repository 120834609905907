import CenteredText from "./CenteredText";
import ContentHighlight from "./ContentHighlight";
import ProductDetails from "./ProductDetails";
import ContentPager from "./ContentPager";
import ContentColumns from "./ContentColumns";
import AlternateContent from "./AlternateContent";
import Media from "./Media";
import Accordion from "./Accordion";
import Contact from "./Contact";
import Manuais from "./Manuais";

export default {
  CenteredText,
  ContentHighlight,
  ProductDetails,
  ContentPager,
  ContentColumns,
  Accordion,
  Media,
  Manuais,
  Contact,
  AlternateContent
};
