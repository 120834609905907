import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Slider from "react-slick";
import { Container } from "reactstrap";

import BreadCrumb from "../Menus/BreadCrumb";
import Menu from "../Menus/Menu";
import MobileMenu from "../Menus/MobileMenu";
import SubMenu from "../Menus/SubMenu";
import { toggleModal } from "../../containers/Menus/actions";
import { getSearch, toggleSearch } from "../../containers/Search/actions";
import "./styles.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stikyClass: false,
      searchOpen: false,
      restart: false,
      currentSlide: 1,

      // headerClass: props.isHome ? 'header' : 'header inner',
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.status === "set" &&
      this.props.current !== prevProps.current &&
      this.props.status !== prevProps.status
    ) {
      this.setState({
        // headerClass: prevProps.isHome ? 'header' : 'header inner',
        restart: true,
      });
    } else if (
      this.props.status === "set" &&
      this.props.current !== prevProps.current &&
      this.state.restart
    ) {
      this.setState({
        // headerClass: this.props.isHome ? 'header' : 'header inner',
        restart: false,
      });
    }
  }

  componentDidMount() {
    if (window) window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    if (window) window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(e) {
    const currentHeight =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (currentHeight > 60 && !this.state.handleScroll) {
      this.setState({ handleScroll: true });
    } else if (currentHeight < 60 && this.state.handleScroll) {
      this.setState({ handleScroll: false });
    }
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  toggleModal(url, id) {
    this.props.toggleModal(url, id);
  }

  render() {
    const { head, head_options } = this.props.content;
    let fullscreen = false;
    let thumbnails = false;

    if (head_options) {
      fullscreen = head_options.fullscreen;
      thumbnails = head_options.thumbnails;
    }

    const settings = {
      dots: thumbnails,
      infinite: true,
      pauseOnHover: false,
      arrows: false,
      fade: true,
      speed: 1000,
      autoplay: head && head.length > 1,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (oldIndex, newIndex) => {
        this.setState({ currentSlide: newIndex + 1 });
      },
      customPaging: (i) => {
        return (
          <a
            style={{
              backgroundImage: `url('${head[i].slide}')`,
            }}
            className={"pager"}
          />
        );
      },
    };

    return (
      <div
        className={`${fullscreen ? "fullscreen" : ""} ${
          head && head.length > 0 ? "header" : "header inner"
        } ${this.state.handleScroll ? "fixedPadding" : ""}`}
      >
        <div className={"menu-container"}>
          <div
            className={`menus clearfix ${
              this.state.handleScroll ? "fixed" : ""
            }`}
          >
            <SubMenu
              content={this.props.submenu}
              location={this.props.current}
              lang={this.props.lang}
              toggleModal={this.toggleModal}
              toggleSearch={this.props.toggleSearch}
            />
            <Container>
              <MobileMenu
                isHome={this.props.isHome}
                content={this.props.topmenu}
                rightmenu={this.props.shopmenu}
                location={this.props.current}
                categories={this.props.categories}
                options={this.props.options.content}
                submenu={this.props.submenu}
                lang={this.props.lang}
                toggleModal={this.toggleModal}
              />

              <Menu
                isHome={this.props.isHome}
                content={this.props.topmenu}
                categories={this.props.categories}
                rightmenu={this.props.shopmenu}
                options={this.props.options.content}
                location={this.props.current}
                lang={this.props.lang}
                toggleModal={this.toggleModal}
              />
            </Container>
          </div>
        </div>
        <BreadCrumb breadcrumbs={this.props.breadcrumbs} />

        {head && !this.state.restart && (
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {head.map((slide, index) => (
              <div key={index} className={"header-wrapper"}>
                <div
                  className={"header-slide"}
                  style={{
                    backgroundImage: `url('${slide.slide}')`,
                  }}
                >
                  <div className={"read-safe"} />
                  {/* <div className={'read-shadow'} /> */}
                </div>
                <div
                  className={"abolute-elemtns animated fadeIn"}
                  style={{
                    animationDelay: `1s`,
                    animationDuration: `2s`,
                  }}
                >
                  <Container>
                    <div className={"header-text-wrapper"}>
                      <div>
                        {slide.sub_title && (
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: slide.sub_title,
                            }}
                          />
                        )}
                        {slide.title && (
                          <h2
                            dangerouslySetInnerHTML={{ __html: slide.title }}
                          />
                        )}

                        {slide.text && (
                          <div
                            dangerouslySetInnerHTML={{ __html: slide.text }}
                          />
                        )}
                        {slide.button && (
                          <a className={"action"} href={slide.link}>
                            {slide.button} <i className="arrow right"></i>
                          </a>
                        )}
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            ))}
          </Slider>
        )}
        {head && head.length > 1 && (
          <div className="arrows" style={{ textAlign: "center" }}>
            <span>
              {this.state.currentSlide}/{head.length}
            </span>
            <a className="prev" onClick={this.previous} />
            <a className="next" onClick={this.next} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  topmenu: state.menus.topmenu,
  categories: state.content.categories,
  submenu: state.menus.submenu,
  options: state.options.options,
  shopmenu: state.menus.shopmenu,
  status: state.content.status,
  breadcrumbs: state.content.breadcrumbs,
  content: state.content.currentContent && state.content.currentContent.acf,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleModal, toggleSearch }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

Header.propTypes = {
  content: PropTypes.object,
  topmenu: PropTypes.array,
  submenu: PropTypes.array,
};

Header.defaultProps = {
  content: {
    slides: [],
  },
  topmenu: [],
  submenu: [],
};
