import LayoutListContentAlternate from './LayoutListContentAlternate';
import LayoutListContentTextSlideshow from './LayoutListContentTextSlideshow';
import LayoutListCategoryAlternate from './LayoutListCategoryAlternate';
import LayoutListContentAlternateBackground from './LayoutListContentAlternateBackground';
import LayoutListHighlight from './LayoutListHighlight';
import LayoutListMap from './LayoutListMap';
import LayoutListGallery from './LayoutListGallery';
import ContentGridSlideshow_List from './ContentGridSlideshow_List';
import ContentGrid_List from './ContentGrid_List';
import ContentGridHighlight_List from './ContentGridHighlight_List';

export default {
  LayoutListContentAlternate,
  LayoutListContentTextSlideshow,
  LayoutListCategoryAlternate,
  LayoutListContentAlternateBackground,
  LayoutListHighlight,
  LayoutListMap,
  LayoutListGallery,
  ContentGridSlideshow_List,
  ContentGridHighlight_List,
  ContentGrid_List,
};
