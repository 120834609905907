import React from 'react';
import './styles.scss';

function Loading(props) {
  return (
    <div className={'section-loading--wrapper'}>
      <div className={'section-loading'}></div>

      <div className="loader">Loading...</div>
    </div>
  );
}

export default Loading;
