import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Button from "../../Button";
import LayoutGridElement from "../../LayoutGridElement";
import Slider from "react-slick";
import config from "../../../../config";
import "./styles.scss";

class CustomSlide extends Component {
  render() {
    const { index, slideIndex, ...props } = this.props;
    let isItActive = false;
    if (index % 3 === 0 && index !== 0 && slideIndex + 1 <= index) {
      isItActive = true;
    }
    return (
      <div className={`slide11 ${isItActive ? "last" : ""}`} {...props}>
        {this.props.children}
      </div>
    );
  }
}

export default class ContentGridSlideshow_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      animated: false,
      buyingOptionsModal: false,
      selectedRegion: null,
    };
    this.transitionEnd = this.transitionEnd.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  transitionEnd() {
    this.setState({ animated: true });
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const attr = this.props.content;
    if (!attr || !attr.content) return null;

    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 500,
      autoplay: false, //attr.content.length > 3,
      autoplaySpeed: 5000,
      slidesToShow: attr.content.length > 4 ? 4 : attr.content.length,
      slidesToScroll: attr.content.length > 3 ? 3 : attr.content.length,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            autoplay: attr.content.length > 3 ? 3 : attr.content.length,
            slidesToScroll: attr.content.length > 2 ? 2 : attr.content.length,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            autoplay: attr.content.length > 2 ? 2 : attr.content.length,
            slidesToScroll: attr.content.length > 1 ? 1 : attr.content.length,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            autoplay: attr.content.length > 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const page =
      parseInt(this.state.slideIndex / (settings.slidesToShow - 1)) + 1;
    const totalPages =
      parseInt(attr.content.length / settings.slidesToShow) + 1;
    return (
      <div>
        {attr.title && (
          <Container>
            <Row>
              <Col xs={{ size: 8, offset: 2 }} className={"section-subtitle"}>
                <h3>{attr.title}</h3>
              </Col>
            </Row>
          </Container>
        )}
        <div id={this.props.id} className={"products-slideshow"}>
          <Container>
            <Row>
              <Col xs="12">
                <div className={"right"}>
                  <ScrollAnimation
                    initiallyVisible={this.props.first}
                    animateOnce={true}
                    animateIn="fadeInUp"
                  >
                    <Slider ref={(c) => (this.slider = c)} {...settings}>
                      {attr.content.map((item, index) => {
                        const imageURL = `${config.apiURL}/wp-content/uploads/products/${item.acf.details.sku}.jpg`;

                        const eneryEfficiency =
                          item.acf &&
                          item.acf.specs &&
                          item.acf.specs.find(
                            (spec) => spec.feature && spec.feature.id == 133
                          );

                        const card = {
                          content:
                            item.acf.details && item.acf.details.description,
                          sub_title: item.sub_title,
                          button_text: attr.button_text,
                          marker: item.marker,
                          energy:
                            eneryEfficiency &&
                            eneryEfficiency.feature &&
                            eneryEfficiency.feature.value,
                          title: item.title,
                          link: item.link.replace(config.websiteURL, ""),
                          image: imageURL,
                        };
                        return (
                          <CustomSlide
                            key={index}
                            index={index}
                            slideIndex={this.state.slideIndex}
                            id={`slides_${this.props.id}`}
                          >
                            <LayoutGridElement {...card} />
                          </CustomSlide>
                        );
                      })}
                    </Slider>
                  </ScrollAnimation>
                  {attr.content.length > 1 && (
                    <div className="arrows" style={{ textAlign: "center" }}>
                      <span className="totals">
                        {page}/{totalPages}
                      </span>
                      <a className="prev" onClick={this.previous} />
                      <a className="next" onClick={this.next} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
