import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

function Categories(props) {
  return (
    <ul className={'categories clearfix'}>
      {props.content &&
        props.content.map((col, index) => (
          <li
            key={index}
            className={'fadeInUp animated'}
            style={{
              animationDelay: `${index + 0.2}s`,
              animationDuration: `2s`,
            }}
          >
            <Link
              to={`${props.language ? '/' + props.language : ''}/${
                props.contentType
              }/types/${col.slug}`}
              className={
                props.currentCategory === col.slug ? 'active' : 'notactive'
              }
            >
              {col.name}
            </Link>
          </li>
        ))}
    </ul>
  );
}

export default Categories;
