import React, { Component } from 'react';
import Title from '../../Title';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from 'reactstrap';
import ReactPlayer from 'react-player';
import './styles.scss';

export default class CenteredText extends Component {
  renderVisual() {
    return this.props.content.tipo === 'video'
      ? this.renderVideo()
      : this.renderImage();
  }

  renderVideo() {
    return (
      <div className={'top-video'}>
        <ReactPlayer
          controls={false}
          config={{
            youtube: {
              playerVars: { showinfo: 0 },
            },
          }}
          url={this.props.content.video}
          width={'100%'}
        />
      </div>
    );
  }

  renderImage() {
    return (
      <div
        className={'top-image'}
        style={{
          backgroundImage: `url('${this.props.content.image &&
            this.props.content.image.sizes.large}')`,
        }}
      ></div>
    );
  }

  render() {
    // const { steps, currentStep } = this.state;
    const { content } = this.props;
    return (
      <div
        className={`content-highlight ${content.image ? '' : 'no-image'} ${
          content.text_colour === 'black' ? 'dark' : 'white'
        }`}
      >
        <div
          className={'wrapper'}
          style={{
            backgroundImage: `url('${content.background_image &&
              content.background_image.sizes.large}')`,
            backgroundColor: `${content.background_colour || '#242424'}`,
          }}
        >
          {content.image && (
            <div className={'top-image-wrapper'}>
              <Container>
                <ScrollAnimation
                  initiallyVisible={this.props.first}
                  animateOnce={true}
                  animateIn="fadeInUp"
                  style={{
                    animationDelay: `0s`,
                    animationDuration: `.6s`,
                  }}
                >
                  {this.renderVisual()}
                </ScrollAnimation>
              </Container>
            </div>
          )}
          <div className={'content-wrapper'}>
            <Container>
              <Row>
                <Col
                  xs="12"
                  md="5"
                  lg="5"
                  className={'align-items-center align-self-center'}
                >
                  <ScrollAnimation
                    initiallyVisible={this.props.first}
                    animateOnce={true}
                    animateIn="fadeInUp"
                  >
                    {content.sub_title && (
                      <h4
                        dangerouslySetInnerHTML={{
                          __html: content.sub_title,
                        }}
                      />
                    )}
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOnce={true}
                    delay={250}
                  >
                    {content.title && (
                      <h3 dangerouslySetInnerHTML={{ __html: content.title }} />
                    )}
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    delay={300}
                    animateOnce={true}
                    initiallyVisible={this.props.first}
                  >
                    {content.content && (
                      <div
                        className={'content'}
                        dangerouslySetInnerHTML={{ __html: content.content }}
                      />
                    )}
                  </ScrollAnimation>
                </Col>
                {content.product_pack && (
                  <Col
                    xs="12"
                    md="7"
                    lg="7"
                    className={
                      'align-items-center align-self-center product-pack'
                    }
                  >
                    <ScrollAnimation
                      animateIn="fadeIn"
                      delay={200}
                      animateOnce={true}
                      initiallyVisible={this.props.first}
                    >
                      <img src={content.product_pack.sizes.large} />
                    </ScrollAnimation>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
