export function scrollToItem(item, buffer = 0, wrapper = window) {
  if (typeof wrapper === 'undefined' || !item) return null;
  const diff = (item.offsetTop - buffer - wrapper.scrollY) / 8;
  if (Math.abs(diff) > 1) {
    wrapper.scrollTo(0, wrapper.scrollY + diff);
    clearTimeout(wrapper._TO);
    wrapper._TO = setTimeout(scrollToItem, 20, item, buffer);
  } else {
    wrapper.scrollTo(0, item.offsetTop - buffer);
  }
}
