import React from "react";
// import { Link } from 'react-router-dom';
import "./styles.scss";
import Button from "../Button";

function convertNumberToInch(number) {
  return (number * 0.39370078740157).toFixed(2);
}

function LayoutGridElement(props) {
  return (
    <div className={"grid-element"}>
      <div className={"content-slide"}>
        <div className={`image ${"animated fadeIn"}`}>
          {props.marker &&
            props.marker.map((mark, i) => {
              if (mark && mark.slug === "new") {
                return (
                  <div key={i} className={"new-product"}>
                    {mark.name}
                  </div>
                );
              }
            })}
          <div className={"image-product"}>
            <div
              className={"pimage"}
              style={{
                backgroundImage: `url(${props.image})`,
              }}
            />
            <div className={"markers"}>
              {props.marker &&
                props.marker.map((mark, i) => {
                  if (
                    mark &&
                    mark.acf &&
                    mark.acf.excerpt &&
                    mark.acf.excerpt.image
                  ) {
                    return (
                      <div
                        key={i}
                        className={"marker"}
                        style={{
                          backgroundImage: `url(${mark.acf.excerpt.image.url})`,
                        }}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <div className={`content ${"animated fadeIn"}`}>
          <h3
            dangerouslySetInnerHTML={{
              __html: props.title.rendered,
            }}
          />
          <p
            className={`position`}
            dangerouslySetInnerHTML={{
              __html: props.sub_title,
            }}
          />

          <div
            className={"text"}
            dangerouslySetInnerHTML={{
              __html: props.content,
            }}
          />

          {props.button_text && (
            <Button to={props.link || ""}>{props.button_text}</Button>
          )}

          {/* {props.energy && (
            <div className={"energy"}>
              <span>{props.energy}</span>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default LayoutGridElement;
