// The basics
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import LoadingBar from "react-redux-loading-bar";
import AddToHomescreen from "react-add-to-homescreen";

// Action creators and helpers
// import { establishCurrentUser } from '../modules/auth';
import { getSystemInit } from "./containers/System/actions";
import { getMenus } from "./containers/Menus/actions";
import { getOptions } from "./containers/Options/actions";
import { setLanguage } from "./containers/Language/actions";
import config from "../config";

import Header from "./components/Header";
import Footer from "./components/Footer";
import GDPR from "./components/GDPR";
import Admin from "./components/Admin";
import Routes from "./routes";

import "normalize.css/normalize.css";
import "animate.css/animate.min.css";
// import 'simple-line-icons/css/simple-line-icons.css';
import "bootstrap/dist/css/bootstrap.css";
import "slick-carousel/slick/slick.css";
import "./app.css";

class App extends Component {
  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      var browserUpdate = require("browser-update");

      browserUpdate({
        required: {
          e: -2,
          i: 11,
          f: -3,
          o: -3,
          s: 10.1,
          c: "64.0.3282.16817",
          samsung: 7.0,
          vivaldi: 1.2,
        },
        insecure: true,
      });
    }
    // react router is not capable of handling this so lets move it to the end of the que
    setTimeout(() => {
      props.setLanguage(props.match.params.lang);
      props.getMenus(props.match.params.lang);
      props.getSystemInit(props.match.params.lang);
    }, 0);
    this.handleAddToHomescreenClick = this.handleAddToHomescreenClick.bind(
      this
    );
  }

  componentWillMount() {
    // this.props.getMenus();
    // if (!isServer) {
    //   this.props.establishCurrentUser();
    // }
  }

  isthishomepage() {
    if (
      this.props.location.pathname === "/" ||
      this.props.location.pathname === "/index.html"
    )
      return true;
    return config.languages.find((lang) => {
      return (
        this.props.location.pathname === `/${lang}` ||
        this.props.location.pathname === `/${lang}/`
      );
    });
  }

  getCurrentPageSlug() {
    const totalSlug = this.props.location.pathname
      .split("/")
      .filter((part) => part !== "");

    return totalSlug && totalSlug.join(" ");
  }

  handleAddToHomescreenClick() {
    alert(`
      1. Open Share menu
      2. Tap on "Add to Home Screen" button`);
  }

  render() {
    return (
      <div
        id="app"
        className={`${this.getCurrentPageSlug()} ${this.props.contentType} ${
          this.isthishomepage() ? "home" : "nothome"
        }`}
      >
        <Header
          current={this.props.location.pathname}
          isHome={this.isthishomepage()}
          lang={this.props.match.params.lang}
        />
        <LoadingBar
          style={{
            zIndex: 999,
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#3b4554",
          }}
        />
        <div
          id="content"
          className={this.isthishomepage() ? "home" : "notHome"}
        >
          <Routes />
        </div>
        <Footer
          footermenu={this.props.footermenu}
          options={this.props.options}
          content={this.props.optionsContent}
          location={this.props.location.pathname}
        />

        <Admin />
        <GDPR content={this.props.gdpr} />
        <AddToHomescreen
          icon={`${config.apiURL}/wp-content/uploads/2019/08/logo-1.svg`}
          title={"Becken we trust! Save it for easy access "}
          onAddToHomescreenClick={this.handleAddToHomescreenClick}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contentType: state.content.currentContent.type,
  options: state.options.options.acf,
  gdpr:
    state.options.options.acf && state.options.options.acf.gdrp
      ? state.options.options.acf.gdrp
      : {},
  optionsContent: state.options.options.content,
  topmenu: state.menus.topmenu,
  footermenu: state.menus.footermenu,
  status: state.content.status,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getMenus, getOptions, setLanguage, getSystemInit },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
