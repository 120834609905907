import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import LayoutGridElement from "../../LayoutGridElement";
import LayoutGridElementHover from "../../LayoutGridElementHover";
import CenteredText from "../../Blocks/CenteredText";

import config from "../../../../config";

import ScrollAnimation from "react-animate-on-scroll";
import "./styles.scss";

export default class ContentGrid_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      content: props.content.content,
      groupedContent: [],
      currentCattegoriesIds: [],
    };
  }

  componentDidMount() {
    if (this.props.status === "set") {
      this.getCurrentCatData();
    }
  }

  componentDidUpdate() {
    if (this.props.status === "getting" && !this.state.loading) {
      this.setState({ loading: true });
    } else if (this.props.status === "set" && this.state.loading) {
      this.setState({ loading: false });
      this.getCurrentCatData();
    }
  }

  getCurrentCatData() {
    const currentCattegoriesIds = [];
    let currentCategory = "";
    this.props.currentCategories.map((current) => {
      if (current) {
        const currentCat = this.props.categories[
          this.props.content.content_type
        ].find((cat) => {
          if (this.props.currentCategory === cat.slug) {
            currentCategory = cat;
          }

          return cat.slug === current;
        });
        if (currentCat) {
          currentCattegoriesIds.push(currentCat.id);
          return currentCat;
        }
      }
    });

    const groupedContent = [];
    // go through content and group
    this.props.content.content.map((cont) => {
      //lets remove uneessary cat present in the url
      const thisCats =
        (cont.categories &&
          cont.categories
            .sort()
            .filter((tc) => !currentCattegoriesIds.includes(tc))
            .join("-")) ||
        currentCategory.id;

      if (!groupedContent[thisCats]) {
        groupedContent[thisCats] = [];
      }
      groupedContent[thisCats].push(cont);
    });
    this.setState({
      groupedContent,
      currentCattegoriesIds: currentCattegoriesIds,
    });
  }

  renderCategoryHeader(category) {
    //get current cat
    const currentCat = this.props.categories[
      this.props.content.content_type
    ].find((cat) => cat.id === category);

    //get cat custom fields
    let background = "";
    let subtitle = "";
    if (currentCat?.acf?.excerpt) {
      background = currentCat.acf.excerpt.image
        ? currentCat.acf.excerpt.image.url
        : "";
      subtitle = currentCat.acf.excerpt.title;
    }
    //render custom fileds
    return (
      <div className={"cat-section"}>
        <h3>{currentCat?.name}</h3>
        <div
          className={`${
            background ? "backgroundImage" : "noBackground"
          } image-cat-holder`}
          style={{
            backgroundImage: `url('${background}')`,
          }}
        >
          {subtitle && (
            <h4
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          )}
        </div>
      </div>
    );
  }

  renderCards(contents) {
    let thisRow = [];
    let i = 0;
    contents &&
      contents.map((content, itemIndex) => {
        //lets wrap conten and clear it
        let elementData = {};
        if (this.props.content.content_type == "products") {
          const imageURL = `${config.apiURL}/wp-content/uploads/products/${content.acf.details.sku}.jpg`;
          //energy efficiency
          const eneryEfficiency =
            content.acf &&
            content.acf.specs &&
            content.acf.specs.find(
              (spec) => spec.feature && spec.feature.id == 133
            );
          //element data
          elementData = {
            type: this.props.content.content_type,
            image: imageURL || "",
            link: content.link.replace(config.websiteURL, ""),
            title: content.title,
            marker: content.marker,
            energy:
              eneryEfficiency &&
              eneryEfficiency.feature &&
              eneryEfficiency.feature.value,
            id: content.id,
            content: content.acf.details && content.acf.details.description,
            addProductToCart: this.props.addProductToCart,
            price: content.price,
            dimensions: content.dimensions,
            button_text: this.props.content.button_text,
            currency_symbol: this.props.currency_symbol,
          };
        } else {
          const item = content.acf.excerpt;
          if (!item) return null;
          elementData = {
            first: itemIndex === 0,
            type: this.props.content.content_type,
            image: item.image || "",
            link: content.link.replace(config.websiteURL, ""),
            title: content.title.rendered,
            content: item.summary,
            button_text: this.props.content.button_text,
          };
        }
        //lets push content into row
        thisRow.push(
          <Col
            className={`${
              this.props.content.content_type == "products" ? "line" : "noline"
            }`}
            key={itemIndex}
            xs="12"
            md={elementData.first ? "12" : "6"}
            lg={elementData.first ? "12" : "4"}
          >
            {/* <div
            className={'fadeInUp animated'}
            style={{
              animationDelay: `${i / 2 + 0.03}s`,
              animationDuration: `1s`,
            }}
          > */}
            {this.props.content.element_layout === "content_under" ? (
              <LayoutGridElement {...elementData} />
            ) : (
              <LayoutGridElementHover {...elementData} />
            )}
            {/* </div> */}
          </Col>
        );
        i++;
      });

    // now we return the global object

    return (
      <Row>
        {thisRow}
        <div className={"white-line"} />
      </Row>
    );
  }

  renderGroup() {
    return (
      <div className={"list-grid"}>
        <Container className={"container-uptop"}>
          {this.state.groupedContent &&
            this.state.groupedContent.map((content, index) => {
              return (
                <div key={index}>
                  {this.renderCategoryHeader(index)}
                  {this.renderCards(content)}
                </div>
              );
            })}
        </Container>
      </div>
    );
  }
  renderSingle() {
    return (
      <div className={"list-grid"}>
        <Container className={"container-uptop"}>
          {this.renderCards(this.props.content.content)}
        </Container>
      </div>
    );
  }
  render() {
    if (this.props.content.content && this.props.content.content.length < 1) {
      const noresult =
        this.props.noResults &&
        this.props.noResults.content &&
        this.props.noResults.content.noresults;

      return (
        <div className={"noResults"}>
          <CenteredText
            first={true}
            content={{
              align: "center",
              sub_title: noresult && noresult.sub_title,
              title: noresult && noresult.title,
              content: noresult && noresult.content,
            }}
          />
        </div>
      );
    } else if (this.props.content.group_by_category) {
      return this.renderGroup();
    } else {
      return this.renderSingle();
    }
  }
}
