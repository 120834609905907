import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

function Button(props) {
  return (
    <Link
      className={'actionButton'}
      onClick={props.onClick}
      to={props.to || '#'}
    >
      {props.children}
    </Link>
  );
}

export default Button;
