import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Page from "../../components/page";
import DynamicContent from "../../components/DynamicContent";
import config from "../../../config";
import Loading from "../../components/Loading";
import { StickyShareButtons } from "sharethis-reactjs";

import {
  getContentPage,
  getAllCategories,
  getMoreContentPage,
  removeCurrentContent,
  removeProductContent,
  getListContent,
} from "../../containers/Content/actions";
import { setLanguage } from "../../containers/Language/actions";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      status: null,
    };
    this.getContentPage = this.getContentPage.bind(this);
    this.loading = false;
    const { lang, content } = props.match.params;
    const loadContent = content === "index.html" ? undefined : content;
    const loadLanguage = lang || props.language;
    const loadType = "products";
    props.getContentPage(
      loadContent,
      loadLanguage,
      loadType,
      null,
      null,
      null,
      ""
    );
  }

  getContentPage(event) {
    const { content, lang } = this.props.match.params;
    if (event.data.adminreload) {
      this.props.getContentPage(content, lang, "products");
    }
  }

  componentDidMount() {
    // stuff for admin edit
    const self = this;
    window.addEventListener("message", this.getContentPage);
    // if (
    //   !this.props.categories ||
    //   !this.props.categories["products"] ||
    //   (this.props.categories &&
    //     this.props.categories["products"] &&
    //     this.props.categories["products"].length === 0)
    // ) {
    //   this.props.getAllCategories(
    //     "products",
    //     this.props.match.params.lang || this.props.language
    //   );
    // }
  }

  componentWillUnmount() {
    this.props.removeProductContent();
    // stuff for admin edit
    const self = this;
    if (this.props.user) {
      window.removeEventListener("message", this.getContentPage);
    }
  }

  componentDidUpdate() {
    if (!this.state.animateTransition)
      this.setState({
        animateTransition: true,
      });
  }

  shouldComponentUpdate(nextProps) {
    const { content, lang } = nextProps.match.params;

    // we need to check if search changed

    if (this.props.match.params.content !== content) {
      this.setState({
        animateTransition: false,
        status: "updating",
      });
      this.loading = false;

      // this.props.removeCurrentContent();
      this.props.getContentPage(content, lang, "products");
    }

    return true;
  }

  render() {
    const { content, status, productSpecs, currentProductContent } = this.props;
    if (!content || !content.acf || !content.acf.details) return <Loading />;

    const yoast_meta = content.yoast_meta;

    return (
      <Page
        id="products_content"
        title={yoast_meta && yoast_meta.yoast_wpseo_title}
        description={yoast_meta && yoast_meta.yoast_wpseo_metadesc}
        link={yoast_meta && yoast_meta.yoast_wpseo_canonical}
      >
        <DynamicContent
          content={{ acf: { content: currentProductContent } }}
          language={this.props.language}
          category={this.props.match.params.category}
          status={status}
        />
        <StickyShareButtons
          config={{
            alignment: "left", // alignment of buttons (left, right)
            color: "white", // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 11, // font size for the buttons
            hide_desktop: false, // hide buttons on desktop (true, false)
            labels: "counts", // button labels (cta, counts, null)
            language: "en", // which language to use (see LANGUAGES)
            min_count: 0, // hide react counts less than min_count (INTEGER)
            networks: [
              // which networks to include (see SHARING NETWORKS)
              "facebook",
              "twitter",
              "pinterest",
              "whatsapp",
              "email",
            ],
            padding: 12, // padding within buttons (INTEGER)
            radius: 4, // the corner radius on each button (INTEGER)
            show_total: true, // show/hide the total share count (true, false)
            show_mobile: true, // show/hide the buttons on mobile (true, false)
            show_toggle: true, // show/hide the toggle buttons (true, false)
            size: 38, // the size of each button (INTEGER)
            top: 300, // offset in pixels from the top of the page

            // OPTIONAL PARAMETERS
            url: yoast_meta && yoast_meta.yoast_wpseo_canonical, // (defaults to current url)
            // image: 'https://bit.ly/2CMhCMC', // (defaults to og:image or twitter:image)
            description: yoast_meta && yoast_meta.yoast_wpseo_metadesc, // (defaults to og:description or twitter:description)
            title: yoast_meta && yoast_meta.yoast_wpseo_title, // (defaults to og:title or twitter:title)
            message: yoast_meta && yoast_meta.yoast_wpseo_metadesc, // (only for email sharing)
            subject: yoast_meta && yoast_meta.yoast_wpseo_title, // (only for email sharing)
            username: "custom twitter handle", // (only for twitter sharing)
          }}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  content: state.content.currentContent,
  currentProductContent: state.content.currentProductContent,
  categories: state.content.categories,
  productSpecs: state.options.productSpecs,
  currentCategory: state.content.currentCategory,
  status: state.content.status,
  user: state.admin.user,
  language: state.language,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContentPage,
      setLanguage,
      getAllCategories,
      removeCurrentContent,
      getMoreContentPage,
      removeProductContent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);

Products.propTypes = {
  content: PropTypes.any,
};

Products.defaultProps = {
  content: {
    yoast_meta: {},
    acf: {
      content: [],
    },
  },
};
