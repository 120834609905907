import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import NoContent from "../../components/NoContent";
import Page from "../../components/page";
import DynamicContent from "../../components/DynamicContent";

import {
  getContentPage,
  getAllCategories,
  getMoreContentPage,
  removeCurrentContent,
} from "../../containers/Content/actions";
import { setLanguage } from "../../containers/Language/actions";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.match.params.content,
    };
    this.getContentPage = this.getContentPage.bind(this);
    this.loadMoreContentPage = this.loadMoreContentPage.bind(this);

    const {
      lang,
      content,
      category,
      type,
      subcategory,
      thirdcategory,
    } = props.match.params;
    const loadContent = content === "index.html" ? undefined : content;
    const loadLanguage = lang || props.language;
    const loadType = type;
    const loadCategory = category;
    const loadSubCategory = subcategory;
    const loadThirdCategory = thirdcategory;
    props.getContentPage(
      loadContent,
      loadLanguage,
      loadType,
      loadCategory,
      loadSubCategory,
      loadThirdCategory,
      this.getSearchTerm()
    );
  }

  getSearchTerm(location) {
    const newLocation = location ? location : this.props.location;
    if (newLocation.search) {
      const params = new URLSearchParams(newLocation.search);
      return params.get("q");
    }
  }

  getContentPage(event) {
    const {
      content,
      lang,
      category,
      type,
      subcategory,
      thirdcategory,
    } = this.props.match.params;

    if (event.data.adminreload) {
      this.props.getContentPage(
        content,
        lang,
        type,
        category,
        subcategory,
        thirdcategory,
        this.getSearchTerm()
      );
    }
  }

  componentDidMount() {
    // stuff for admin edit
    const self = this;
    window.addEventListener("message", this.getContentPage);
    if (
      !this.props.categories ||
      !this.props.categories["products"] ||
      (this.props.categories &&
        this.props.categories["products"] &&
        this.props.categories["products"].length === 0)
    ) {
      this.props.getAllCategories(
        "products",
        this.props.match.params.lang || this.props.language
      );
    }
  }

  componentWillUnmount() {
    this.props.removeCurrentContent();
    // stuff for admin edit
    const self = this;
    if (this.props.user) {
      window.removeEventListener("message", this.getContentPage);
    }
  }

  componentDidUpdate() {
    if (!this.state.animateTransition)
      this.setState({
        animateTransition: true,
      });
  }

  shouldComponentUpdate(nextProps) {
    const {
      content,
      lang,
      category,
      categories,
      type,
      subcategory,
      thirdcategory,
    } = nextProps.match.params;

    // we need to check if search changed
    const oldTerm = this.getSearchTerm();
    const newTerm = this.getSearchTerm(nextProps.location);

    if (
      this.props.match.params.content !== content ||
      this.props.match.params.category !== category ||
      this.props.match.params.thirdcategory !== thirdcategory ||
      this.props.match.params.subcategory !== subcategory ||
      oldTerm !== newTerm
    ) {
      this.setState({
        ishome: !content,
        content: content,
        animateTransition: false,
      });
      // this.props.removeCurrentContent();
      this.props.getContentPage(
        content,
        lang,
        type,
        category,
        subcategory,
        thirdcategory,
        newTerm
      );
      window.scrollTo(0, 0);
    }

    return true;
  }

  loadMoreContentPage(type, page, total) {
    // for override if we are not in a category page
    const { category } = this.props.match.params;
    const newTerm = this.getSearchTerm(this.props.location);

    this.props.getMoreContentPage(
      page,
      this.props.language,
      type,
      total,
      category && this.props.currentCategory,
      newTerm
    );
  }

  render() {
    const { content, status, content_404 } = this.props;
    //lets take care of loading
    if (
      (!content || !content.acf || !content.acf.content) &&
      status === "getting"
    ) {
      return <Loading />;
    } else if (
      (!content && status === "set") ||
      (content.lenght > 0 &&
        content.acf &&
        !content.acf.content &&
        !content.acf.header &&
        status === "set")
    ) {
      return <NoContent content={content_404} />;
    } else if (
      status === "set" &&
      content &&
      content.acf &&
      content.acf.content &&
      content.acf.content.length > 0 &&
      content.acf.content[0].acf &&
      !content.acf.content[0].acf.content &&
      !content.acf.head
    ) {
      return <NoContent content={content_404} />;
    } else if (status === "nocontent") {
      return <NoContent content={content_404} />;
    }

    const yoast_meta = content.yoast_meta;
    return (
      <Page
        id="page_content"
        title={yoast_meta && yoast_meta.yoast_wpseo_title}
        description={yoast_meta && yoast_meta.yoast_wpseo_metadesc}
        link={yoast_meta && yoast_meta.yoast_wpseo_canonical}
      >
        <DynamicContent
          content={content}
          language={this.props.language}
          category={
            this.props.match.params.thirdcategory ||
            this.props.match.params.subcategory ||
            this.props.match.params.category
          }
          currentCategories={[
            this.props.match.params.category,
            this.props.match.params.subcategory,
            this.props.match.params.thirdcategory,
          ]}
          noResults={content_404}
          categories={this.props.categories}
          status={status}
          loadMoreContentPage={this.loadMoreContentPage}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  content: state.content.currentContent,
  categories: state.content.categories,
  currentCategory: state.content.currentCategory,
  content_404: state.options.options.acf,
  status: state.content.status,
  user: state.admin.user,
  language: state.language,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContentPage,
      getAllCategories,
      setLanguage,
      removeCurrentContent,
      getMoreContentPage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content);

Content.propTypes = {
  content: PropTypes.any,
};

Content.defaultProps = {
  content: {
    yoast_meta: {},
    acf: {
      content: [],
    },
  },
};
