import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import LayoutGridElement from '../../LayoutGridElement';
import LayoutGridElementHover from '../../LayoutGridElementHover';
import CenteredText from '../../Blocks/CenteredText';
import config from '../../../../config';

import ScrollAnimation from 'react-animate-on-scroll';
import './styles.scss';

export default class ContentGridHighlight_List extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { content, title, background_colour } = this.props.content;
    let contentProps = {};
    let categories = [];
    // if (this.props.content.category) {
    //   categories = this.props.categories[this.props.content.content_type].find(
    //     cat => cat.id === this.props.content.category
    //   );
    //   contentProps = {
    //     content: { background_colour },
    //   };
    // }

    if (categories) {
      contentProps = {
        ...contentProps,
        content: {
          ...contentProps.content,
          title: title || categories.name,
          sub_title: categories.name,
          content:
            categories.acf &&
            categories.acf.excerpt &&
            categories.acf.excerpt.content,
        },
      };
    }

    return (
      <div
        className={'list-grid-highlight'}
        style={{ backgroundColor: background_colour }}
      >
        {this.props.content.category && categories && categories.acf && (
          <div>
            <CenteredText {...contentProps}>
              <ScrollAnimation
                animateIn="fadeInUp"
                delay={650}
                animateOut="fadeOut"
                initiallyVisible={this.props.first}
                animateOnce={true}
                // animateOnce={this.props.first}
              >
                <Link
                  className={'see-more'}
                  to={this.props.content.categories.link.replace(
                    config.websiteURL,
                    ''
                  )}
                >
                  {this.props.content.button_text} ›
                </Link>
              </ScrollAnimation>
            </CenteredText>

            {categories.acf.excerpt && (
              <Container>
                <div
                  className={'excerpt-image'}
                  style={{
                    backgroundImage: `url('${categories.acf.excerpt.image &&
                      categories.acf.excerpt.image.sizes.large}')`,
                  }}
                />
              </Container>
            )}
          </div>
        )}
        <Container className={'container-uptop'}>
          <Row>
            {this.props.content.content &&
              this.props.content.content.map((content, itemIndex) => {
                const item = content.acf.excerpt;
                if (!item) return null;
                const elementData = {
                  first: itemIndex === 0,
                  type: this.props.content.content_type,
                  image: item.image,
                  link: content.link.replace(config.websiteURL, ''),
                  title: content.title.rendered,
                  content: item.summary,
                  button_text: this.props.content.button_text,
                };

                if (this.props.content.element_layout !== 'content_under') {
                  return (
                    <Col
                      key={itemIndex}
                      xs="12"
                      md={itemIndex === 0 ? '12' : '4'}
                    >
                      <div
                        className={`fadeInUp animated ${
                          itemIndex === 0 ? 'first' : ''
                        }`}
                        style={{
                          animationDelay: `${itemIndex / 2 + 0.03}s`,
                          animationDuration: `1s`,
                        }}
                      >
                        <LayoutGridElementHover {...elementData} />
                      </div>
                    </Col>
                  );
                } else {
                  return (
                    <Col key={itemIndex} xs="12" md="4">
                      <div
                        className={'fadeInUp animated'}
                        style={{
                          animationDelay: `${itemIndex / 2 + 0.03}s`,
                          animationDuration: `1s`,
                        }}
                      >
                        <LayoutGridElement {...elementData} />
                      </div>
                    </Col>
                  );
                }
              })}
          </Row>
        </Container>
      </div>
    );
  }
}
