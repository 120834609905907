import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import config from '../../../config';
function ReadMore(props) {
  return (
    <Link className={'readmore'} to={props.link.replace(config.websiteURL, '')}>
      {props.children} <i className={'icons icon-arrow-right'} />
    </Link>
  );
}

export default ReadMore;
