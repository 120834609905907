import React, { Component } from 'react';
import './styles.scss';
import config from '../../../../config';

export default class LoadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reachedBottom: false,
      loading: false,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    const content = this.props.content;
    const currentPage = content.page || 1;

    this.props.loadMoreContentPage(content.content_type, currentPage + 1);
  }
  previous() {
    const content = this.props.content;
    const currentPage = content.page || 1;
    this.props.loadMoreContentPage(content.content_type, currentPage - 1);
  }

  getMoreContent(content) {
    const currentPage = content.page || 1;

    return (
      content.totalpages > 1 && (
        <div className="pager clearfix">
          <div className="arrows" style={{ textAlign: 'center' }}>
            <span>
              {currentPage}/{content.totalpages}
            </span>
            {currentPage > 1 && <a className="prev" onClick={this.previous} />}
            {currentPage < content.totalpages && (
              <a className="next" onClick={this.next} />
            )}
          </div>
        </div>
      )
    );
  }

  componentDidUpdate() {
    // lets reset to allow to load more content on reach bottom
    if (
      (this.props.status === 'set' || this.props.status === 'updated') &&
      this.state.loading
    ) {
      this.setState({ loading: false });
    }
  }

  render() {
    if (!this.props.content || !this.props.content.load_more) return null;
    return this.getMoreContent(this.props.content);
  }
}
