import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './styles.scss';
import FooterMenu from '../Menus/FooterMenu';

export default class Contact extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { options } = this.props;
    const currentDate = new Date();
    if (!options || !options.content.logo) return null;
    return (
      <div className={'footer-wrapper animated fadeInUp'}>
        <div className={'footer'}>
          {options && (
            <Container>
              <Row>
                <Col xs="12" md="2">
                  {options.content.logo && (
                    <div
                      className={'logo'}
                      style={{
                        backgroundImage: `url(${options.content.logo.url})`,
                      }}
                    ></div>
                  )}
                </Col>
                <Col xs="12" md="6" className={'sub-menu-wrapper'}>
                  <FooterMenu
                    isHome={this.props.isHome}
                    options={this.props.options}
                    content={this.props.footermenu}
                    location={this.props.location}
                  />
                </Col>
                <Col xs="12" md="4">
                  {options.content.text && (
                    <div
                      className={'content-wrapper'}
                      dangerouslySetInnerHTML={{ __html: options.content.text }}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          )}
        </div>
        <Container>
          <Row>
            <Col xs="12" className={'rights'}>
              © {currentDate.getFullYear()} Becken. All rights reserved.
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
